@import '_variables';
@import 'mixin';
.wrap-join {
    margin-top: 4.8rem;
    padding-top: 4.8rem;

    //height: calc(100vh - rem)은 여백스크롤 방지, 하단 button-wrap을 바닥에 붙이기 위함
    &:has(.step-2, .step-4, .step-5, .step-6) {
        .step-2,
        .step-4,
        .step-5,
        .step-6 {
            height: calc(100vh - 15rem);
        }
        .join {
            padding: 0;
        }
    }
    &.join-setting-animal {
        .step-3 {
            height: calc(100vh - 18.1rem);
        }
    }
    &:has(.slide-wrap) {
        & {
            margin: 0;
            padding: 0;
        }
        .slide-dots {
            height: 2.4rem;
            // border: 1px solid red;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 6.4rem;
        }
    }
    &:has(.login-button-wrap) {
        & {
            margin: 0;
            padding: 0;
        }
    }
}
@media (min-width: 720px) {
    .wrap-join {
        &.join-setting-animal {
            .step-3 {
                height: calc(100vh - 21rem);
            }
        }
    }
}
body:has(.intro-access) {
    .intro-access {
        height: calc(100vh - 5.3rem);
    }
    .main {
        padding-bottom: 0;
        .title-xl {
            line-height: 1.3;
        }
        ul {
            li {
                padding: 0.45rem 1.3rem;
                p {
                    font-weight: 400;
                }
            }
        }
        .reference {
            padding: 1rem 0;
        }
    }
    .button-wrap {
        position: initial !important;
        transform: initial;
        padding-top: 0 !important;
        padding-right: 2rem !important;
        padding-bottom: 0 !important;
        padding-left: 2rem !important;
    }
}
@media (min-width: 720px) {
    body:has(.login-button-wrap) {
        .login {
            &.agreement {
                height: calc(100vh - 13rem);
            }
        }
    }
}
.member {
    width: 100%;
    margin: 0 auto;
    .profile-setting-nick & {
        padding-top: 4.6rem;
    }
    .slide-dots {
        margin-top: 5rem;
    }
    .main {
        padding: 0 2rem 11rem;
        .title-xl {
            color: #000;
            padding: 8.8rem 0 6rem 0;
        }
        ul {
            margin-bottom: 5.6rem;
            li {
                display: flex;
                gap: 2.4rem;
                padding: 0.5rem 1.3rem;
                align-items: center;
                & + li {
                    margin-top: 0.8rem;
                }
                img {
                    width: 2.4rem;
                    height: 2.4rem;
                }
                strong {
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    color: #202020;
                    margin-bottom: 0.2rem;
                    span {
                        font-size: 1.4rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
                p {
                    font-size: 1.2rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: $color-grayb-600;
                }
            }
        }
    }

    .main-slide {
        padding: 5.8rem 2rem 0 2rem;
        .slide-wrap {
            .slide-content {
                // margin-bottom: 6.4rem;
                .slide {
                    text-align: center;
                    .slide-img {
                        margin-bottom: 5.8rem;
                        img {
                            border-radius: 0.5rem;
                        }
                    }
                    p {
                        font-size: 1.6rem;
                        font-style: normal;
                        font-weight: 700;
                        color: #000;
                    }
                }
            }
            .slide-dots {
                width: 100%;
                ul {
                    display: flex;
                    justify-content: center;
                    gap: 0.8rem;
                    align-items: center;
                    li {
                        .dots {
                            width: 0.8rem;
                            height: 0.8rem;
                            border-radius: 50%;
                            background-color: #eaeeed;
                            &.active {
                                background-color: $color-main-500;
                            }
                        }
                    }
                }
            }
        }
    }

    .login {
        width: 100%;
        padding: 12.45rem 2rem 11rem;
        text-align: center;
        &.agreement {
            padding: 12.45rem 2rem 0 2rem;
        }
        .title-xl {
            color: $color-main-500;
            font-size: 2.6rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.26rem;
            margin-bottom: 5.4rem;
            .point-g {
                display: block;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.14rem;
            }
        }
        .login-img {
            margin-bottom: 5.6rem;
            img {
                width: 30rem;
                height: 24rem;
            }
        }
        .login-button-wrap {
            width: 100%;
            ul {
                display: flex;
                flex-direction: column;
                gap: 0.8rem;
                li {
                    &.apple-login {
                        width: 100%;
                        height: 5.2rem;
                        border: 0.1rem solid #000;
                        background-color: #000;
                        a {
                            color: #fff;
                            font-size: 1.6rem;
                            font-weight: 700;
                        }
                    }
                    a {
                        display: block;
                        width: 100%;
                        border-radius: 1.6rem;
                        height: 5.4rem;
                        text-align: center;
                        padding: 1.6rem;
                        font-size: 1.6rem;
                        font-style: normal;
                        font-weight: 700;
                        position: relative;
                        font-weight: 400;
                        font-size: 1.4rem;
                        &:before {
                            content: '';
                            width: 3rem;
                            height: 3rem;
                            position: absolute;
                            left: 1.6rem;
                            top: 50%;
                            transform: translate(0, -50%);
                        }
                        &.google-login {
                            border: 0.1rem solid #eee;
                            color: #1d1c2b;
                            font-weight: 700;
                            font-size: 1.6rem;
                            &:before {
                                background: url('../images/ico_google_30.svg')
                                    no-repeat left 50%;
                            }
                        }
                        &.kakao-login {
                            border: 0.1rem solid #fee500;
                            background-color: #fee500;
                            color: rgba(0, 0, 0, 0.85);
                            font-weight: 700;
                            font-size: 1.6rem;
                            &:before {
                                background: url('../images/ico_kakao_30.svg')
                                    no-repeat left 50%;
                            }
                        }
                        &.naver-login {
                            border: 0.1rem solid #03c75a;
                            background-color: #03c75a;
                            color: #fff;
                            font-size: 1.6rem;
                            font-weight: 700;
                            &:before {
                                background: url('../images/ico_naver_30.svg')
                                    no-repeat left 50%;
                            }
                        }
                        &.apple-login {
                            border: 0.1rem solid #000;
                            background-color: #000;
                            color: #fff;
                            font-size: 1.6rem;
                            font-weight: 700;
                            &:before {
                                width: 100%;
                                height: 100%;
                                background: url('../images/ico_apple_30.svg')
                                    no-repeat left 50%;
                            }
                        }
                        &.join-btn {
                            font-weight: 400;
                            padding-top: 3rem;
                            text-decoration: underline;
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
    }
    .join {
        padding: 0 0 11rem;
        &.coupon {
            margin-top: 4.8rem;
        }
        .terms-title {
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
            border-bottom: 0.1rem solid $color-main-500;
            img {
                vertical-align: sub;
                margin-right: 0.8rem;
            }
        }
        .title-l {
            font-size: 2.4rem;
            font-weight: 700;
            color: #000;
        }
        .step-1 {
            padding: 2.4rem 2rem 0;
        }
        .join-input {
            input[type='text'] {
                // 기본
                &::placeholder {
                    color: $color-grayb-600;
                    font-weight: 500;
                }
                /* input에 값 없을 때 */
                &:placeholder-shown {
                    border-bottom: 1px solid $color-grayb-600 !important;
                }
                /* input에 값 있을 때 */
                &:not(:placeholder-shown) {
                    color: $black00 !important;
                    border-bottom: 1px solid $black00 !important;
                }
                // 포커스될 때. 이걸 :not(:placeholder-shown)뒤에 넣어야 우선순위 높아져서 적용됨.
                &:focus {
                    caret-color: $color-main-500;
                    border-bottom: 2px solid $color-main-500 !important;
                }
            }
            strong {
                display: block;
                margin-bottom: 0.8rem;
                font-size: 1.8rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            input {
                width: 100%;
                height: 4.2rem;
                @include body-me($color-grayb-600);
                &::placeholder {
                    @include body-me($color-grayb-600);
                }
            }
            .success-input {
                input {
                    @include body-me($color-grayb-600);
                    // border-bottom: 0.2rem solid #40DF9F;
                    &:focus {
                        caret-color: $color-main-500;
                        border-bottom: 0.2rem solid $color-main-500;
                    }
                }
                .success {
                    color: $color-main-600;
                }
            }
            .fail-input {
                input {
                    color: $color-grayb-500;
                    // border-bottom: 0.2rem solid #FF567B;
                }
                .fail {
                    color: $color-danger-500;
                }
            }
            span {
                display: block;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        .step-2 {
            padding: 0 2rem;
            .title-l {
                margin-bottom: 13.9rem;
                .profile-setting-nick & {
                    margin-bottom: 13.5rem;
                }
            }
            .join-input {
                strong {
                    display: block;
                    margin-bottom: 0.8rem;
                    font-size: 1.8rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                input {
                    width: 100%;
                    height: 4.2rem;
                    @include body-me($color-grayb-600);
                    &::placeholder {
                        @include body-me($color-grayb-600);
                    }
                }
                .success-input {
                    input {
                        @include body-me($color-grayb-600);
                    }
                    .success {
                        color: $color-main-600;
                        margin-top: 0.4rem;
                    }
                }
                .fali-input {
                    input {
                        color: $color-grayb-600;
                        border-bottom: 0.2rem solid $color-danger-500;
                    }
                    .fail {
                        color: $color-danger-500;
                    }
                }
                span {
                    display: block;
                    font-size: 1.2rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-top: 0.4rem;
                }
            }
        }
        .step-3 {
            // padding: 0 0 3rem;
            .title-l {
                margin-bottom: 0.8rem;
                padding: 0 2rem;
            }
            .reference {
                margin-bottom: 2.4rem;
                padding: 0 2rem;
            }
            .checkbox-list {
                ul {
                    li {
                        h3 {
                            font-size: 1.6rem;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin-bottom: 0;
                        }
                        .checkbox-button {
                            margin-top: 0.4rem;
                            margin-left: 0.4rem;
                        }
                    }
                }
            }
        }
        .location {
            width: 100%;
            strong {
                padding: 1.15rem 1.6rem;
                background-color: $color-main-50;
                border-radius: 0.8rem;
                font-size: 1.8rem;
                font-weight: 700;
                color: $color-main-600;
                display: inline-block;
            }
        }
        .step-4 {
            padding: 0 2rem;
            .map-wrap {
                .location {
                    text-align: center;
                    margin: 3.5rem 0 1.6rem;
                }
                .map-box {
                    text-align: center;
                    position: relative;
                    .location-button {
                        position: absolute;
                        z-index: 9;
                        background-color: #36423d;
                        color: $color-grayb-50;
                        padding: 0.8rem 1.6rem;
                        border-radius: 0.6rem;
                        font-size: 1.4rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        bottom: 0.8rem;
                        right: 0.8rem;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .step-4_2 {
            padding: 0 2rem;
            .map-wrap {
                .location {
                    text-align: center;
                    margin: 3.5rem 0 0 0;
                    strong {
                        background-color: $color-grayb-50;
                        color: $color-grayb-600;
                        font-weight: 500;
                    }
                }
                .map-box {
                    margin-top: 6.2rem;
                    text-align: center;
                    img {
                        width: 28rem;
                        height: 32rem;
                    }
                }
            }
        }
        .step-5 {
            padding: 0 2rem;
            .title-l {
                span {
                    font-size: 1.8rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    color: $color-main-500;
                }
            }
            .reference {
                padding-top: 0.8rem;
            }
            .content {
                &.margin-top-139 {
                    margin-top: 13.9rem;
                }
            }
            .map-wrap {
                margin-top: 4.5rem;
                .bookmark-box {
                    .bookmark {
                        font-size: 1.8rem;
                        font-weight: 700;
                        h3 {
                            font-size: 1.8rem;
                            font-weight: 700;
                        }
                        & + .bookmark {
                            margin-top: 6.3rem;
                            .location {
                                margin-top: 2.4rem;
                            }
                        }
                        h3 {
                            margin-bottom: 2.1rem;
                        }
                    }
                }
                .bookmark-add {
                    margin-top: 2.4rem;
                    button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 13.7rem;
                        height: 4.8rem;
                        border-radius: 0.6rem;
                        border: 0.05rem dashed $color-grayb-600;
                        background-color: $color-grayb-50;
                        line-height: 2;
                    }
                }
            }
        }
        .step-6 {
            padding: 11rem 2rem 0 2rem;
            .title-xl {
                color: #40df9f;
                letter-spacing: -0.2rem;
            }
            .reference-s {
                color: #6debac;
            }
            .map-wrap {
                margin-top: 4.5rem;
                .bookmark p {
                    font-size: 2rem;
                    color: $color-grayb-300;
                }
                .bookmark-add {
                    margin-top: 2.4rem;
                    button {
                        width: 13.7rem;
                        height: 4.8rem;
                        border-radius: 0.6rem;
                        border: 0.05rem dashed $color-grayb-600;
                        background-color: $color-grayb-50;
                    }
                }
            }

            //추가
            .adm-form-item-label {
                display: block;
                height: 100%;
                font-style: normal;
                font-weight: 700;
                line-height: 1.5;
            }
            .adm-form-item.adm-form-item-vertical .adm-form-item-label {
                font-size: var(--adm-font-size-10);
                margin-bottom: 0.8rem;
            }

            .adm-form .adm-list.adm-list {
                --prefix-padding-right: 0;
                --padding-left: 0;
                --padding-right: 0;
            }
        }
    }
    &.wallet-write-split-month {
        .join {
            .title-l {
                font-size: 2.2rem;
            }
        }
    }
}
body:has(.setting) {
    .step-5 {
        margin-top: 4.4rem;
    }
}
.reference-xs-g {
    font-weight: 400;
}

.member:has(.main-slide-popup) {
    .popup-wrap4 {
        background: none;
    }
    .slide-content {
        border-radius: 1.6rem 1.6rem 0 0;
        overflow: hidden;
    }
    .adm-swiper-horizontal {
        img {
            object-fit: cover;
        }
    }

    .slide-dots {
        padding: 1rem 0 0.3rem 0;
    }
    .slide-dots,
    .slide-img {
        margin: -0.1rem 0 0 0 !important;
        // padding-top: 0.4rem;
        //
    }
    .adm-swiper {
        height: 43.6rem;
        // border-top: 0.01rem solid #fff;
        // border-left: 0.01rem solid #fff;
        // border-right: 0.01rem solid #fff;
    }
    .slide-dots,
    .button-wrap2 {
        background-color: #fff;
        box-sizing: border-box;
    }
    .button-wrap2 {
        margin-top: -0.1rem;
        border-radius: 0 0 1.6rem 1.6rem;
    }
}

@media (max-width: 719px) {
    .wrap-join {
        .step-4_2 {
            .reference {
                margin-top: 0.8rem;
            }
        }
        .join {
            padding: 0;
        }
        .button-wrap {
            position: initial;
            padding-top: 0;
            padding-right: 2rem;
            padding-bottom: 0;
            padding-left: 2rem;
            transform: initial;
        }
        .map-box {
            // padding-bottom: 6.3rem;
            // 왜했지.. 주소수정 버튼 밖으로 나오는데 이거하면
        }
        &.intro-access {
            padding: 0;
            margin: 0;
        }
    }
    body:has(.terms-agree-modal) {
        main {
            overflow-x: hidden;
        }
        .agreement {
            // padding: 0 !important;
        }
    }
}
@media (min-width: 720px) {
    .wrap-join {
        .button-wrap {
            padding-bottom: 2rem;
        }
    }
}
body:has(.slide-pc-title) {
    .slide-pc-title {
        text-align: center;
        font-size: 1.8rem;
        font-weight: 400;
    }
    .slide-content {
        .slide {
            p {
                font-size: 0 !important;
            }
        }
    }
    .slide-img {
        margin-bottom: 0 !important;
    }
}

.wrap.wrap-join {
    .member {
        .login.agreement {
            .title-xl {
                letter-spacing: -0.15rem;
            }
            .point-g {
                color: $color-main-400 p !important;
                letter-spacing: -0.05rem;
                font-weight: 400;
            }
        }
    }
}
.need-home-text {
    @include logo-h1($color-main-500);
    p {
        font-size: 1.4rem;
        font-weight: 500;
        color: $color-main-400;
        line-height: normal;
        letter-spacing: -1.4px;
    }
    &.login-page {
        text-align: center;
    }
    &.join-page {
        text-align: left;
    }
}
