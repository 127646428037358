@import '_variables';

.home {
    width: 100%;
    position: relative;
    padding-bottom: 5rem;
    .main & {
        padding-bottom: 4.3rem;
    }
    .visual-wrap {
        margin: 0 auto 1.6rem;
        text-align: center;
        padding: 0 2rem;
        img {
            width: 100%;
        }
    }

    .choice-wrap {
        // margin-bottom:5rem;
        // &.main{
        //   margin-top:3.2rem;
        // }
        .choice-slide {
            .slide-wrap {
                .slide-content {
                    overflow-x: auto;
                    display: flex;
                    padding-left: 2rem;
                    .slide {
                        .slide-img {
                            position: relative;
                            width: 24rem;
                            height: 24rem;
                            border-radius: 1.5rem;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                            p {
                                position: absolute;
                                bottom: 1.25rem;
                                left: 1rem;
                                padding-right: 1rem;
                                font-size: 1.8rem;
                                font-weight: 700;
                                color: #fff;

                                display: -webkit-box;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                            }
                        }
                    }
                }
            }
        }
    }

    .monthly-wrap {
        padding: 0 2rem;
        &.list {
            // padding-top: 1.6rem;
            padding-top: 6.375rem;
        }
        ul {
            display: flex;
            gap: 1.6rem 1.5rem;
            flex-wrap: wrap;
            margin-top: 1rem;
            li {
                width: calc(100% / 2 - 0.8rem);
                &.thumb-main {
                    overflow: hidden;
                    div.adm-image {
                        position: relative;
                        width: 100%;
                        border-radius: 1.6rem;
                        overflow: hidden;
                        &::before {
                            content: '';
                            display: block;
                            padding-top: 100%; /* 1:1 비율 */
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                        }
                    }
                    p {
                        margin: 0.8rem;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        font-size: 1.4rem;
                        font-weight: 700;
                        word-wrap: break-word;
                        color: #000;
                    }
                    div {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.adm-image {
    img {
        object-fit: cover !important;
    }
}
.title-box-main {
    .choice-wrap & {
        padding: 1.1rem 2rem;
    }
    h2 {
        font-size: 2rem;
        font-weight: 700;
    }
    .more {
        color: $color-main-500;
        font-size: 1.4rem;
        font-weight: 500;
    }
}
.writer {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $color-grayb-500;
    text-align: right;
    margin-top: 0.85rem;
    .choice-wrap & {
        display: flex;
        justify-content: flex-end;
        gap: 0.6rem;
    }
    .list-article & {
        // gap: 0.6rem !important;
        // 아이콘과의 사이간격 너무넓어짐
    }
    .writer-area {
        margin-right: 0.2rem;
    }
    .bg-color {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        background: #de5792;
        border-radius: 0.4rem;
    }
    img {
        vertical-align: -0.18rem;
    }
}

.neighbor-add {
    margin-bottom: 2rem;
}

.company-info-wrap {
    padding-top: 2.4rem;
    padding-bottom: 3.2rem;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    background: $color-grayb-50;
    color: $color-grayb-400;
    ul.terms {
        // margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.8rem 0.8rem;
        font-size: 1.2rem;
        font-weight: 400;
        @media (max-width: 342px) {
            gap: 1rem;
            flex-direction: column;
        }
        li {
            // padding: 0 0.8rem;
            display: inline-block;
            white-space: nowrap;
            &:nth-of-type(1),
            &:nth-of-type(2) {
                padding-right: 0.8rem;
                @media (max-width: 342px) {
                    padding-right: 0;
                }
            }
            + li {
                position: relative;
                padding-left: 1.25rem;
                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    // transform: translateX(-50%);
                    display: inline-block;
                    content: '|';
                    // color: blue;
                    color: $color-grayb-200;
                    font-size: 1.2rem;

                    @media (max-width: 342px) {
                        left: 0;
                        content: '';
                    }
                }

                @media (max-width: 342px) {
                    padding-left: 0;
                }
            }
            a {
                color: $color-grayb-400;
                font-weight: 500;
            }
        }
    }
    .company-info {
        padding: 0 2rem;
        margin-bottom: 6.4rem;
        .company-title {
            padding: 0.8rem 0;
            display: flex;
            align-items: center;
            gap: 0.8rem;
            > p {
                font-size: 1.2rem;
                font-weight: 700;
            }
            > button {
                width: 1.6rem;
                height: 1.6rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .company-content {
            padding: 0.8rem 0;
            font-size: 1.2rem;
            font-weight: 500;
            ul {
                display: flex;
                flex-direction: column;
                gap: 0.8rem;
                li {
                    display: grid;
                    grid-template-columns: 8rem 1fr;
                    gap: 0 3.2rem;
                }
            }
        }
    }
}
@media (max-width: 499px) {
}
@media (min-width: 500px) {
    ul.terms {
        li {
            &:nth-of-type(1),
            &:nth-of-type(2) {
                padding-right: 1.6rem !important;
            }
            + li {
                padding-left: 1.7rem !important;
            }
        }
    }
}
.diary-wrap.content.pd-lr-30 {
    h2 {
        font-size: 2rem;
    }
}
.hand-book-detail {
    .hand-book-detail-con {
        margin-top: 5.6rem;
        .diary-wrap {
            display: flex;
            flex-direction: column;
            gap: 3.2rem;
            .title-wrap {
                h2 {
                    margin-bottom: 0;
                }
            }
            .info {
                margin-right: 0.8rem;
                margin-bottom: 0.8rem;
            }
            .comn-box {
                width: 100%;
                height: 12rem;
                &:has(.empty-con) {
                    height: 10rem;
                }
            }
            .log-wrap {
                display: flex;
                flex-direction: column;
                gap: 1.6rem;
                margin-bottom: 0;
                .title-wrap,
                .log-box {
                    margin-bottom: 0;
                }
            }
        }
    }
}
