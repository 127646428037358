@import "_variables";
@import "mixin";
.setting{
  width: 100%;
  padding: 1.6rem 0 0;
  .animal-add-main & {
    padding: 4.2rem 0 0 0 ;
  }
  .round-box{
    margin-bottom: 2.4rem;
  }
  
  .profile-list{
    ul{
      margin-bottom: 1.6rem;
      li{
        display: flex;
        gap: 1.3rem;
        align-items: center;
        padding: 0.8rem 0 1.8rem;
        p{
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .text{
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        + li{
          border-top: 0.1rem solid $color-grayb-100;
          padding-top: 1.6rem;
        }
      }
    }
    button{
      width: 100%;
      height: 4.2rem;
      border-radius: 0.8rem;
      background-color: $color-grayb-50;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #BDBDBD;
      .text{
        margin: 0 0.8rem;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #BDBDBD;
      }
    }

    .profile-content{
      .text.flex-align{
        display: flex;
        align-items: center;
        gap: 0.8rem;
        margin-top: 0.4rem;
        .label-l {
          margin-right: 0;
        }
        .span-group {
          display: flex;
          gap: 0.8rem;
          span {
            line-height: 1.6;
          }
        }
      }
    }
  }

  .profile-writing{ 
    width: 100%;
    padding: 4.8rem 2rem 0 2rem;
    >  div{
      width: 100%;
      display: flex;
      gap: 1.6rem;
      align-items: center;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $color-grayb-600;
      + div{
        margin-top: 2.4rem;
      }
      p{
        padding: 1rem;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-grayb-600;
      }
    }
    .select{
      .animal-choice{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 4.2rem;
        font-size: 1.4rem;
        border-bottom: 0.1rem solid $color-grayb-600;
        cursor: pointer;
      }
    }
    select{
      height: 4.2rem;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $color-grayb-300;
      background:url('../images/icon-arrow-bottom.svg') no-repeat 97% 50%/2.4rem auto;
      border-bottom: 0.1rem solid $color-grayb-300;
      
    }
    .input-box{
      width: 100%;
      span{
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #000;
        &.fail{
          color: $color-danger-500;
        }
      }
    }
    .weight{
      .input-box{
        position: relative;
        span{
          position: absolute;
          right: 0.5rem;
          bottom: 1rem;
        }
      }
    }
    .gender{
      .radio-wrap{
        display: flex;
        gap: 8rem;
        .ico-radio{
          margin-right: 0.8rem;
        }
      }
    }
  }
  .pd-20.default{
    .round-box{
      .profile-content{
        p{
          color:#BDBDBD;
          font-weight:500;
        }
      }
    }
  }
  .profile-add-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    &.active{
      background-color: #F1FCF6;
      .text{
        color:$color-main-600
      }
    }
    .label-l {
      margin-right: 0;
    }
  }
  .bookmark{
    .reference-s{
      color: $color-grayb-400;
    }
  }
  .bookmark__add{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.4rem;
    button {
      line-height: 2;
    }
  }

}
button.add-margin{
  margin-right: 0.4rem;
}
.cmp-base-button-label {
  color: $color-grayb-400;
  font-weight: 700;
  &.false {
    // color: $color-grayb-400;
  }
}
.setting.asset{
  text-align: center;
  .section{
    &:nth-of-type(1){
      margin: 2.4rem 0 4.8rem 0;
      .text-group{
        .cph-black{
          @include caption-noto ($black00);
          padding-top: 0.3rem;
        }
      }
    }
  }
  .round-box{
    padding: 1.6rem 1.25rem 1.6rem 1.25rem;
    border-radius: 1.5rem;
    ul{
      li{
        border-bottom: 0.05rem solid $color-grayb-100;
        .item{
          display:flex;
          justify-content: space-between;
          align-items: center;
          padding:0 2rem;
          width:100%;
          height: 5.6rem;
          margin-bottom: 0.8rem;
          span {
            font-size: 1.6rem;
            color: #000;
          }
        }
        .mg-add{
          margin: 1.6rem 0 0.8rem 0;
        }
        &.no-border{
          border:0;
        }
        &.last-text{
          p{
            padding-top: 0.8rem;
            color:$color-grayb-600;
            font-size: 1.2rem;
            text-align: center;
          }
        }
      }
    }
    
    .item:first-child{
      padding-top:0;
    }
  }
}
.asset-add {
  margin-top: 5.6rem;
  .join {
    .title-l {
      margin-bottom: 1rem;
      font-size: 2.2rem;
    }
    input[type=text] {
      // 기본
      &::placeholder {
        color: $color-grayb-600;
      }
      /* input에 값 없을 때 */
      &:placeholder-shown {
        border-bottom: 1px solid $color-grayb-600; 
      }
      /* input에 값 있을 때 */
      &:not(:placeholder-shown) {
        color: $black00;
        border-bottom: 1px solid $black00; 
      }
      // 포커스될 때. 이걸 :not(:placeholder-shown)뒤에 넣어야 우선순위 높아져서 적용됨.
      &:focus {
        caret-color: $color-main-500;
        border-bottom: 2px solid $color-main-500; 
      }
    }
    .join-input {
      strong {
        margin-bottom: 1rem;
      }
    }
    .content.asset-add-content {
      margin-top: 8.5rem;
    }
  }
  &.setting-budget {
    .join {
      .content.asset-add-content {
        margin-top: 6.5rem;
      }
    }
  }
}
.asset-add, .wallet-write-split-month, .note-share{
  .content.asset-add-content, .content.wallet-write-content{
    margin-top: 8.3rem;
    .asset-add-name{
      strong{
        font-size: 1.6rem;
      }
    }
    
  }
  &.type2{
    .content{
      ul{
        margin-top: 4.8rem;
        li{
          margin-top: 0.8rem;
          height: 4.3rem;
          display:flex;
          align-items: center;
          + li {
            border-top: 0.1rem solid $color-grayb-100;
          }
          button{
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
.note-share{
  .join{
    padding-bottom:0;
  }
}
.data-import{
  .data-radio{
    .import-file{
      display:flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 0.8rem;
      margin-bottom:25%;
      height: 4.4rem;
      font-size: 1.4rem;
      background-color: #FAFAFA;
      border-radius: 0.8rem;
      .file-name{
        margin-left: 1.2rem;
      }
    }
  }
}

.ext3{
  .ext-text{
    span{
      font-size: 1.2rem;
    }
    span:first-child{
      
      color:$color-grayb-500;
    }
    span:last-child{
      color:$color-danger-500;
    }
  }
  .round-box{
    margin-bottom: 5rem;
  }
  .how-to-box{
    margin-bottom: 4rem;
  }
}
.how-to-box{
  text-align: left;
  width:100%;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 0.8rem;
  background-color: $color-grayb-50;
  .title{
    margin-bottom: 2rem;
    font-size: 1.6rem;
    font-weight:600;
    color:$color-grayb-500;
  }
  .content{
    line-height:1.4;
    font-size: 1.4rem;
    font-weight:300;
    color:$color-grayb-500;
  }
  .content:last-child{
    margin-top: 2rem;
    
  }
}


.setting-service{
  
  padding:0 2rem;
  font-size: 1.4rem;
  .terms-title{
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 1.5rem;
    margin-top: 2.4rem;
    margin-bottom: 1.5rem;
    border-bottom: 0.1rem solid $color-main-500;
    img{
      vertical-align: sub;
      margin-right: 0.8rem;
    }
  }
  
  .terms-content{
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    a {
      word-break:break-all;
    }
    ul {
      li {
        list-style-type: disc;
      }
    }
    p {
      @include body-me ($black00);
      line-height: normal;
      word-break:break-all;
      &.sentence-title {
        @include head-h5 ($black00);
        margin-top: 0.8rem;
        line-height: normal;
      }
      strong {
        font-weight: 700;
        &.pt-8 {
          display: inline-block;
        }
      }
    }
    .indent {
      padding-left: 0.8rem;
      word-break:break-all;
      &:has(.number) {
        padding-top: 0.8rem;
        padding-left: 1.6rem;
      }
    }
    .indent-after {
      padding-top: 0.8rem;
    }
    .number {
      &::before {
        margin-left: -1.6rem;
        padding-right: 0.5rem;
        display: inline-block;
        content: '';
      }
      + .number {
        padding-top: 0.8rem;
      }
      &.item01::before {
        content: '1.';
      }
      &.item02::before {
        content: '2.';
      }
      &.item03::before {
        content: '3.';
      }
      &.item04::before {
        content: '4.';
      }
      &.item05::before {
        content: '5.';
      }
      &.item06::before {
        content: '6.';
      }
      &.item07::before {
        content: '7.';
      }
      &.item08::before {
        content: '8.';
      }
    }
  }


}
.terms-title2{
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.logo-mcb{
  width: 2.3rem;
  height:auto;
  margin-left: 0.8rem;
}
.setting-notification{
  width: 100%;

  .title-box{
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    .title-discription{
      font-size: 1.2rem;
      color:#A4B7AF;
    }
  }
  
  .link-box{
    li{
      &.time{
        height: 4.8rem;
        &:last-child {
          margin-bottom: 2.4rem;
        }
      }
      span:nth-of-type(2){
        color:$color-main-600;
      }
    }

  }
  
}
.list-link-box {
  .title-box {
    .title-m {
      .label-l {
        margin-left: 0.4rem;
        vertical-align: 5px;  
      }
    }
    button {
      display: flex;
      align-items: center;
      img {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
.join-setting-animal, .setting-animal {
  .checkbox-list {
    ul {
      padding-left: 2rem ;
      padding-right: 0;
    }
  }
}
body:has(.setting-animal) {
  .setting-animal {
    margin-top: 4.6rem;
  }
  .fix-bottom, .button-wrap { 
    position: initial;
    transform: initial;
  }
}
@media(max-height: 720px) {
  
  .setting-animal {
    .join {
      padding-bottom: 4.5rem;
    }
  }
  .join-setting-animal {
    &.wrap-join, .member {
     
      height: calc(100vh - 3rem);
    }
  }
}
.join.coupon {
  > h2.title-l {
    padding-bottom: 0.3rem;
  }
  .content {
    margin-top: 11rem;
  }
}

body:has(.asset) {
  .no-border {
    button {
      display: flex;
      align-items: center;
      line-height: 1;
    }
  }
}
.setting-asset, .expend-group {
  padding-bottom: 1.6rem;
  .round-box {
    padding: 0.8rem 1.25rem 1.6rem 1.25rem !important;
      ul li .item {
      margin-top: 0.8rem;
    }
  }
  &.setting {
    padding-top: 0;
  }
}
.wallet-manage-member {
  &.setting {
    padding-top: 0;
  }
  .section:nth-of-type(1) {
    margin: 2.4rem 0 6.6rem 0 !important;
  }
}
.setting-asset-modify {
  .faq-bottom {
    .info-b {
      span {
        @include sub-title-bold ($color-grayb-600);
        margin-left: 0;
      }
    }
    .text-wrap {
      display: flex;
      flex-direction: column;
      gap: 1.8rem;
      .info-g {
        @include body-me ($color-grayb-500);
      }
    }
  }
}