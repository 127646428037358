@import '_variables';
@import 'mixin';
.hidden {
    visibility: hidden;
}
.cph-white {
    color: $white;
}

body {
    font-family: 'Noto Sans';
    font-weight: 400;
}
#root {
    min-height: 100vh;
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        min-height: -webkit-fill-available;
    }
    > div {
        border: 1px solid #fff;
    }
}

main {
    max-width: 72rem;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: #fff;
}
.splash {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
}
.title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-size: 2rem;
        font-weight: 700;
        color: #000;
        .neigbor & {
            line-height: 1;
            padding-top: 0.6rem;
            padding-bottom: 2rem;
        }
    }

    a {
        font-size: 1.4rem;
        font-weight: 500;
        color: $color-main-500;
    }
}

.title-xl {
    font-size: 2.6rem;
    font-weight: 700;
}
.title-l {
    font-size: 2.4rem;
    font-weight: 700;
}
.title-m {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: normal;
}
.title-s {
    font-size: 1.8rem;
    font-weight: 700;
}
.title-xs {
    font-size: 1.6rem;
    font-weight: 700;
}
.title-xxs {
    font-size: 1.4rem;
    font-weight: 700;
}

.through {
    text-decoration: line-through;
}

.font-size-0 {
    font-size: 0 !important;
}
.border-n {
    border: none !important;
}

//포인트컬러

.point-g {
    color: $color-main-600;
}
.point-g-choice {
    color: #39c48d;
}
.point-gr {
    color: $color-grayb-400;
}
.point-gr500 {
    color: $color-grayb-500;
}
.point-r {
    color: $color-danger-500;
    p {
        color: $color-danger-500;
    }
}
.point-y {
    color: $color-warning-500;
    p {
        color: $color-warning-500;
    }
}
.point-b {
    color: $color-info-500;
}
.point-bold {
    font-weight: 700;
}
.info-message {
    width: 100%;
    padding: 1.85rem 1.2rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 1.55rem;
    &.point-r {
        background-color: $color-danger-100;
    }
    &.point-y {
        background-color: $color-warning-100;
    }
    &.point-g {
        background-color: $color-main-50;
    }
    &.point-b {
        background-color: $color-info-100;
    }
    strong {
        display: inline-block;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-right: 0.8rem;
        &.point-b {
            color: $color-info-600;
        }
    }
    span {
        display: block;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        p {
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            .point-link {
                display: inline-block;
                text-decoration: underline;
                color: #366cf4;
            }
        }
    }
}

//세로정렬
.flex-align {
    display: flex;
    align-items: center;
}
//header
header {
    width: calc(100% - 72rem);
    background-color: #fff;
    height: 4.8rem;
    h1 {
        font-size: 2rem;
        font-weight: 700;
        color: #000;
    }

    .header-icon {
        display: flex;
        // gap: 1.2rem;
        align-items: center;
        .write-success {
            font-size: 1.4rem;
            font-weight: 500;
            color: $color-grayb-500;
        }
        .write-success2 {
            font-size: 1.4rem;
            font-weight: 400;
            color: $color-grayb-600;
        }
    }
    .header {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page-header {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .page-title {
            margin: 0 auto;
            font-size: 1.8rem;
            font-weight: 400;
        }
        &.join-title-center {
            display: flex;
        }
    }
    .search-header {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            flex: 0 0 2.4rem;
        }
        .search-wrap {
            // width: 100%;
            height: 4rem;
            padding: 0 0.8rem;
            // margin: 0 auto;
            border: 0.1rem solid $color-grayb-300;
            border-radius: 0.8rem;
            background-color: $color-grayb-50;
            display: grid;
            grid-template-columns: 2.4rem 1fr 2.4rem;
            gap: 1rem;
            img {
                width: 2.4rem;
                height: 2.4rem;
            }
            input {
                box-sizing: border-box;
                font-size: 1.4rem;
                font-weight: 500;
                background: transparent;
                border: 0;
                &:focus {
                    caret-color: unset;
                }
                &::placeholder {
                    color: $color-grayb-500;
                }
            }
        }
    }
}
body:has(.wrap-join) {
    .page-header {
        display: flex;
    }
}
// 통계페이지
body:has(.statistics-wrap) {
    .page-header {
        .page-title {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
                position: absolute;
                top: 0.25rem;
                right: -3.5rem;
                display: inline-block;
                padding: 0.4rem 0.4rem 0.2rem;
                content: 'Beta';
                font-family: GmarketSansMedium;
                font-size: 1rem;
                font-weight: 500;
                letter-spacing: -0.06rem;
                border-radius: 0.5rem;
                line-height: 1.4rem;
                background: #f1fcf6;
                color: #2ebf93;
            }
        }
    }
}
.page-header-3col {
    padding: 4.4rem 0.4rem 0 0.4rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
        margin: 0 auto;
        font-size: 1.8rem;
        font-weight: 400;
    }
}
.header-tab {
    display: flex;
    gap: 1.7rem;
    padding-left: 0.8rem;
    button {
        font-size: 2rem;
        font-weight: 700;
        color: #e0e0e0;
        &.active {
            color: #000;
        }
    }
}
//wrap
.wrap {
    position: relative;
    width: 100%;
    // height: calc(100% - 4.8rem);
    background-color: #fff;
    // &.main{
    //   margin-top: 4rem;
    // }
    &:has(.calendar-inline-wrap) {
        border-radius: 2.4rem 2.4rem 0 0;
        overflow: hidden;
    }
}
body:has(.animal-add-main) {
    .wrap {
        &.animal-add-main {
            height: calc(100vh - 8rem);
        }
    }
    .button-wrap {
        padding: 2.4rem 2rem 0 2rem;
    }
}
@media (min-width: 720px) {
    body:has(.animal-add-main) {
        .wrap {
            &.animal-add-main {
                height: calc(100vh - 9.5rem);
            }
        }
    }
}
.h100per {
    height: 100%;
    &.community-search {
        height: 100%;
    }
}
.layout-content:has(.writing-button-wrap) {
    .h100per {
        height: calc(100vh - 17rem);
    }
}
.wrap.pb-30 {
    padding-bottom: 3rem;
}

//설명글
.reference-s {
    font-size: 1.4rem;
    font-weight: 500;
    color: $color-grayb-600;
}
.reference-s2 {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: normal;
    color: $color-grayb-600;
}
.reference-xs {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: normal;
    color: $color-grayb-600;
}
.reference-xs-g {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: $color-grayb-600;
}
//버튼
.button-wrap {
    max-width: 72rem;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 2.4rem 2rem 3.2rem;
    background: #fff;

    > * {
        border: 0;
        display: block;
        width: 100%;
        padding: 1.6rem 1rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 700;
        border-radius: 1.6rem;
        &.reference {
            padding: 0 0 1.3rem;
        }
    }
    .normal {
        background-color: #36423d;
    }
    .disabled {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-grayb-50;
        color: $color-grayb-400;
        cursor: default;
    }
    .active {
        background-color: $color-main-500;
        color: #fff;
        font-weight: 700;
    }
    .active-bk {
        background-color: #36423d;
        color: #fff;
        font-weight: 500;
    }
    .inactive {
        background-color: $color-grayb-50;
        color: $color-grayb-400;
    }
}

@media (max-width: 719px) {
    .button-wrap {
        padding: 2rem 2.4rem 0 2rem;
    }
    .wrap-join {
        .button-wrap {
            z-index: 10;
        }
    }
}
@media (min-width: 720px) {
    .button-wrap {
        //  padding: 2rem 2.4rem 0 2rem;
    }
}
.button-wrap-agreement {
    max-width: 72rem;
    width: 100%;
    padding-top: 2.4rem;
    padding-right: 2rem;
    padding-bottom: 0;
    padding-left: 2rem;
    background: #fff;

    > * {
        border: 0;
        display: block;
        width: 100%;
        padding: 1.7rem 1rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 700;
        border-radius: 1.6rem;
        &.reference {
            padding: 0 0 1.3rem;
        }
    }
    .normal {
        background-color: #36423d;
    }
    .disabled {
        background-color: $color-grayb-50;
        color: $color-grayb-400;
    }
    .active {
        background-color: $color-main-500;
        color: #fff;
    }
    .active-bk {
        background-color: #36423d;
        color: #fff;
    }
    .inactive {
        background-color: $color-grayb-50;
        color: $color-grayb-400;
    }
}
@media (min-width: 720px) {
    .button-wrap-agreement {
        padding-bottom: 2rem;
    }
}
.save {
    color: $color-main-500 !important;
}
.main-more {
    color: $color-main-500;
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
}
.round-box-wrap64-line-active {
    @include sub-title-bold($color-main-600);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6.4rem;
    border-radius: 1.6rem;
    background-color: $color-main-50;
    text-align: center;
}
.round-box-wrap64-line-inactive {
    @include sub-title-bold($color-grayb-500);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6.4rem;
    border-radius: 1.6rem;
    background-color: $color-grayb-50;
    text-align: center;
}
.button-wrap56-type2-active {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.6rem;
    background-color: $color-main-50;
    border-radius: 0.8rem;
    button {
        color: $color-main-600;
        font-weight: 700;
        font-size: 1.6rem;
        img.icon-asset-plus {
            padding-right: 0.4rem;
        }
    }
}
button.button-wrap56-type2-active {
    color: $color-main-600;
    font-weight: 700;
    font-size: 1.6rem;
}
.button-wrap56-type2-inactive {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.6rem;
    background-color: $color-grayb-50;
    border-radius: 0.8rem;
    button {
        color: #bdbdbd;
        font-weight: 700;
        font-size: 1.6rem;
        img.icon-asset-plus {
            padding-right: 0.4rem;
        }
    }
}

.button-wrap2 {
    width: 100%;
    position: relative;
    padding: 2.4rem 2rem 3.2rem;

    > * {
        border: 0;
        display: block;
        width: 100%;
        padding: 1.7rem 1rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 700;
        border-radius: 1.6rem;
        &.reference {
            padding: 0 0 1.3rem;
        }
    }
    .normal {
        background-color: #36423d;
        color: #fff;
    }
    .disabled {
        background-color: $color-grayb-50;
        color: $color-grayb-400;
    }
    .active {
        background-color: $color-main-500;
        color: #fff;
    }
}

.check-subscription-plan {
    color: $color-main-600;
    font-weight: 700;
    font-size: 1.4rem;
}
.gray-text {
    color: #bdbdbd;
    font-size: 1.2rem;
    font-weight: 400;
}
// input[type=text]{
//   border-bottom: 0.1rem solid $color-grayb-600;
//   &::placeholder {
//     color: $color-grayb-600;
//   }
//   &:focus {
//     border-bottom: 0.1rem solid $color-main-500;
//     caret-color: $color-main-500;
//   }
// }

.title-input {
    margin-bottom: 0.8rem;
    input {
        width: 100%;
        font-size: 1.8rem;
        font-weight: 800;
        color: $black00;
        border: none;
        &::placeholder {
            font-size: 1.8rem;
            font-weight: 800;
            color: $color-grayb-400;
        }
        &:focus {
            border: none;
            caret-color: unset;
        }
    }
}

textarea {
    padding: 0;
    font-family: 'Noto Sans';
    width: 100%;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: normal;
    color: $black;
}
.community-writing {
    .title-input {
        input {
            color: $black00 !important;
            &::placeholder {
                color: $color-grayb-400;
            }
        }
    }
    .writing-textarea {
        textarea {
            &::placeholder {
                color: $color-grayb-400;
            }
        }
    }
    .count {
        color: $color-grayb-500;
    }
}
//체크박스
//checkbox
.checkbox {
    position: relative;
    label {
        font-size: 1.3rem;
        font-weight: 500;
    }

    input[type='checkbox'] {
        display: none;
    }
    input[type='checkbox'] + label span {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: middle;
        background-image: url('../images/checkbox-off.svg');
        background-repeat: no-repeat;
        background-size: 2rem 2rem;
        cursor: pointer;
    }
    input[type='checkbox'] + label span.check-mark {
        width: 2.1565rem;
        height: 2.4rem;
        background-image: url('../images/check-inactive.svg');
    }
    input[type='checkbox']:checked + label span {
        width: 2rem;
        height: 2rem;
        background-image: url('../images/checkbox-on.svg');
        background-repeat: no-repeat;
        background-size: 2rem 2rem;
    }
    input[type='checkbox']:checked + label span.check-mark {
        width: 2.1565rem;
        height: 2.4rem;
        background-image: url('../images/check-active.svg');
    }
}
.ext3 {
    .check-mark {
        margin-right: 1.6rem;
    }
}
.checkbox2 {
    position: relative;
    label {
        font-size: 1.3rem;
        font-weight: 500;
        .subscription-cancel-step2 & {
            display: flex;
            align-items: center;
            font-size: 1.6rem;
        }
    }

    input[type='checkbox'] {
        display: none;
    }
    input[type='checkbox'] + label span {
        display: inline-block;
        width: 2.4rem;
        height: 2.4rem;
        vertical-align: middle;
        background-image: url('../images/checkbox-off.svg');
        background-repeat: no-repeat;
        background-size: 2.4rem 2.4rem;
        cursor: pointer;
    }

    input[type='checkbox']:checked + label span {
        width: 2.4rem;
        height: 2.4rem;
        background-image: url('../images/checkbox-on.svg');
        background-repeat: no-repeat;
        background-size: 2.4rem 2.4rem;
    }
}
.checkbox-button {
    position: relative;
    label {
        @include body-me($color-grayb-500);
        padding: 0.8rem 1.2rem;
        border-radius: 3.2rem;
        background-color: $color-grayb-50;
    }

    input[type='checkbox'] {
        display: none;
    }

    input[type='checkbox'] + label {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        // border: 0.1rem solid red;
    }

    input[type='checkbox']:checked + label {
        @include body-bold($color-main-600);
        background-color: #f1fcf6;
    }
}

.checkbox-tab {
    position: relative;
    label {
        font-size: 1.4rem;

        font-weight: 500;

        padding: 0.8rem 1.2rem;
        border-radius: 3.2rem;
        margin-bottom: 0.4rem;
    }

    input[type='checkbox'] {
        display: none;
    }

    input[type='checkbox'] + label {
        display: inline-block;
        vertical-align: middle;
        border: 0.1rem solid $color-grayb-300;
        background-color: #fff;
        cursor: pointer;
        color: $color-grayb-500;
    }

    input[type='checkbox']:checked + label {
        border: 0.1rem solid #2ebf93;
        color: #2ebf93;
        background-color: #f1fcf6;
        font-size: 1.4rem;

        font-weight: 700;
    }
}

//radio box
.radio-wrap {
    position: relative;

    label {
        font-size: 1.3rem;
        font-weight: 400;
        cursor: pointer;
    }

    input[type='radio'] {
        display: none;
    }

    input[type='radio'] + label span {
        display: inline-block;
        width: 2.4rem;
        height: 2.4rem;
        vertical-align: middle;
        background-image: url('../images/radio-off.svg');
        background-repeat: no-repeat;
        background-size: 2.4rem 2.4rem;
        cursor: pointer;
    }

    input[type='radio']:checked + label span {
        width: 2.4rem;
        height: 2.4rem;
        background-image: url('../images/radio-on.svg');
        background-repeat: no-repeat;
        background-size: 2.4rem 2.4rem;
    }
}
//switch 체크
/*.switch-box{
  display: inline-block;
  .switch {
    position: relative;
    display: inline-block;
    width: 66px;
    height: 26px;
    vertical-align: middle;
    input {
      display: none;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ddd;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 13px;
    span {
      font-size: 15px;
      color: #fff;
      position: absolute;
      top: 7px;
      left: 11px;
      -webkit-transition: .4s;
      transition: .4s;
      &.on {
        opacity: 0;
      }
      &.off {
        left:31px;
      }
    }
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 3rem;
    width: 3rem;
    left: 0;
    bottom: -2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border: 0.1rem solid #ddd;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: #4fa5d8;
    .on {
      opacity: 1;
    }
    .off {
      opacity: 0;
    }
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(38px);
    -ms-transform: translateX(38px);
    transform: translateX(38px);
    border-color:#4fa5d8;
  }
  p {
    display:inline-block;
    margin-left: 0.4rem;
    vertical-align: -2px;
    font-size: 1.6rem;
    color:$color-grayb-600;
  }
  .switch.selected + p {
    color:#000;
  }
}*/

.switch-box {
    input {
        &[switch] {
            display: none;

            + label {
                font-size: 1.6rem;
                line-height: 1;
                width: 8rem;
                height: 3rem;
                background-image: none;
                border-radius: 2rem;
                padding: 0.16667rem;
                cursor: pointer;
                display: inline-block;
                text-align: center;
                position: relative;
                box-shadow: inset 0.25rem 0.17rem 0.7rem 0 rgba(0, 0, 0, 0.3);
                background-color: rgb(255, 255, 255);
                font-family: inherit;
                -webkit-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out;

                &:before {
                    /* Label */
                    text-transform: uppercase;
                    color: #9c57f5;
                    content: '거부';
                    display: block;
                    font-family: inherit;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 1.74rem;
                    position: absolute;
                    right: 1.21667rem;
                    margin: 0.21667rem;
                    top: 0.5rem;
                    text-align: center;
                    min-width: 1.66667rem;
                    overflow: hidden;
                    -webkit-transition: all 0.1s ease-in-out;
                    transition: all 0.1s ease-in-out;
                }

                &:after {
                    /* Slider */
                    content: '';
                    position: absolute;
                    left: 0.16667rem;
                    background-color: #9c57f5;
                    box-shadow: none;
                    border-radius: 2rem;
                    height: 3rem;
                    width: 3rem;
                    -webkit-transition: all 0.1s ease-in-out;
                    transition: all 0.1s ease-in-out;
                }
            }

            &:checked + label {
                box-shadow: inset 0.25rem 0.17rem 0.7rem 0 rgba(0, 0, 0, 0.2);
                background-color: rgb(156, 87, 245);

                &:before {
                    color: #fff;
                    content: '수신';
                    right: auto;
                    left: 1.21667rem;
                    top: 0.5rem;
                }

                &:after {
                    left: 5.2rem;
                    background-color: #f7f7f7;
                    box-shadow: 0.1rem 0.1rem 1rem 0 rgba(0, 0, 0, 0.3);
                }
            }
        }

        &[switch='bool'] {
            + label {
                background-color: #ee6562;

                &:before {
                    color: #fff !important;
                }
            }

            &:checked + label {
                background-color: #bce954;

                &:before {
                    color: #fff !important;
                }
            }
        }

        &[switch='default']:checked + label {
            background-color: #a2a2a2;

            &:before {
                color: #fff !important;
            }
        }

        &[switch='success']:checked + label {
            background-color: #bce954;

            &:before {
                color: #fff !important;
            }
        }

        &[switch='warning']:checked + label {
            background-color: gold;

            &:before {
                color: #fff !important;
            }
        }
    }
}

.switch-box2 {
    position: relative;
    input {
        &[switch] {
            display: none;
            + label {
                font-size: 1.6rem;
                line-height: 1;
                width: 5rem;
                height: 3rem;
                background-image: none;
                border-radius: 2rem;
                cursor: pointer;
                display: inline-block;
                text-align: center;
                position: relative;
                background-color: $color-main-500;
                font-family: inherit;
                -webkit-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out;

                &:before {
                    /* Label */
                    text-transform: uppercase;
                    color: #9c57f5;
                    content: '';
                    display: block;
                    font-family: inherit;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 1.74rem;
                    position: absolute;
                    right: 1.3rem;
                    margin: 0.21667rem;
                    top: 0.29rem;
                    text-align: center;
                    min-width: 1.66667rem;
                    overflow: hidden;
                    -webkit-transition: all 0.1s ease-in-out;
                    transition: all 0.1s ease-in-out;
                }

                &:after {
                    /* Slider */
                    content: '';
                    position: absolute;
                    left: 0.2rem;
                    background-color: #f7f7f7;
                    box-shadow: rgba(0, 0, 0, 0.2);
                    border-radius: 2rem;
                    height: 2.5rem;
                    width: 2.5rem;
                    top: 0.24rem;
                    -webkit-transition: all 0.1s ease-in-out;
                    transition: all 0.1s ease-in-out;
                }
            }

            &:checked + label {
                background-color: $color-main-500;

                &:before {
                    color: #fff;
                    content: '';
                    right: auto;
                    left: 1.3rem;
                    top: 0.24rem;
                }

                &:after {
                    left: 1.4rem;
                    background-color: #f7f7f7;
                    box-shadow: rgba(0, 0, 0, 0.2);
                }
            }
        }

        &[switch='bool'] {
            + label {
                background-color: #ee6562;

                &:before {
                    color: #fff !important;
                }
            }

            &:checked + label {
                background-color: #bce954;

                &:before {
                    color: #fff !important;
                }
            }
        }

        &[switch='default']:checked + label {
            background-color: #a2a2a2;

            &:before {
                color: #fff !important;
            }
        }

        &[switch='success']:checked + label {
            background-color: #bce954;

            &:before {
                color: #fff !important;
            }
        }

        &[switch='warning']:checked + label {
            background-color: gold;

            &:before {
                color: #fff !important;
            }
        }
    }
}

.switch-box3 {
    input {
        &[switch] {
            display: none;

            + label {
                font-size: 1.6rem;
                line-height: 1;
                width: 11rem;
                height: 3.2rem;
                background-image: none;
                border-radius: 2rem;
                padding: 0.16667rem;
                cursor: pointer;
                display: inline-block;
                text-align: center;
                position: relative;
                background-color: #f5f5f5;
                font-family: inherit;
                -webkit-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out;

                &:before {
                    /* Label */
                    text-transform: uppercase;
                    content: '동물';
                    display: block;
                    font-family: inherit;
                    position: absolute;
                    right: 1rem;
                    margin: 0.21667rem;
                    top: 0.1rem;
                    text-align: center;
                    min-width: 1.66667rem;
                    overflow: hidden;
                    -webkit-transition: all 0.1s ease-in-out;
                    transition: all 0.1s ease-in-out;
                    font-size: 1.4rem;
                    font-weight: 500;
                    line-height: 1.6;
                    color: #bdbdbd;
                }

                &:after {
                    /* Slider */
                    content: '분류';
                    position: absolute;
                    left: 0.16667rem;
                    top: 0.4rem;
                    background-color: #fff;
                    box-shadow: none;
                    border-radius: 2rem;
                    width: 5.6rem;
                    height: 2.4rem;
                    -webkit-transition: all 0.1s ease-in-out;
                    transition: all 0.1s ease-in-out;
                    font-size: 1.4rem;
                    font-weight: 700;
                    line-height: 1.6;
                }
            }

            &:checked + label {
                background-color: #f5f5f5;

                &:before {
                    content: '분류';
                    right: auto;
                    left: 1rem;
                    top: 0.1rem;
                    font-size: 1.4rem;
                    font-weight: 500;
                    line-height: 1.6;
                    color: #bdbdbd;
                }

                &:after {
                    content: '동물';
                    left: 5rem;
                    top: 0.4rem;
                    background-color: #fff;
                    font-size: 1.4rem;
                    font-weight: 700;
                    line-height: 1.6;
                }
            }
        }

        &[switch='bool'] {
            + label {
                background-color: #ee6562;

                &:before {
                    color: #fff !important;
                }
            }

            &:checked + label {
                background-color: #bce954;

                &:before {
                    color: #fff !important;
                }
            }
        }

        &[switch='default']:checked + label {
            background-color: #a2a2a2;

            &:before {
                color: #fff !important;
            }
        }

        &[switch='success']:checked + label {
            background-color: #bce954;

            &:before {
                color: #fff !important;
            }
        }

        &[switch='warning']:checked + label {
            background-color: gold;

            &:before {
                color: #fff !important;
            }
        }
    }
}
.switch-box4 {
    input {
        &[switch] {
            display: none;

            + label {
                font-size: 1.6rem;
                line-height: 1;
                width: 11rem;
                height: 3.6rem;
                background-image: none;
                border-radius: 2rem;
                padding: 0.16667rem;
                cursor: pointer;
                display: inline-block;
                text-align: center;
                position: relative;
                background-color: #f5f5f5;
                font-family: inherit;
                -webkit-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out;

                &:before {
                    /* Label */
                    text-transform: uppercase;
                    content: '달력';
                    display: block;
                    font-family: inherit;
                    position: absolute;
                    right: 1.1rem;
                    margin: 0.21667rem;
                    top: 0.4rem;
                    text-align: center;
                    min-width: 1.66667rem;
                    overflow: hidden;
                    -webkit-transition: all 0.1s ease-in-out;
                    transition: all 0.1s ease-in-out;
                    font-size: 1.4rem;
                    font-weight: 500;
                    line-height: 1.6;
                    color: #bdbdbd;
                }

                &:after {
                    /* Slider */
                    content: '목록';
                    position: absolute;
                    left: 0.16667rem;
                    top: 0.2rem;
                    background-color: #fff;
                    box-shadow: none;
                    border-radius: 2rem;
                    width: 5.6rem;
                    height: 3.2rem;
                    -webkit-transition: all 0.1s ease-in-out;
                    transition: all 0.1s ease-in-out;
                    font-size: 1.4rem;
                    font-weight: 700;
                    line-height: 2.2;
                }
            }

            &:checked + label {
                background-color: #f5f5f5;

                &:before {
                    content: '목록';
                    right: auto;
                    left: 1.2rem;
                    top: 0.4rem;
                    font-size: 1.4rem;
                    font-weight: 500;
                    line-height: 1.6;
                    color: #bdbdbd;
                }

                &:after {
                    content: '달력';
                    left: 5.1rem;
                    line-height: 2.2;
                    background-color: #fff;
                    font-size: 1.4rem;
                    font-weight: 700;
                }
            }
        }

        &[switch='bool'] {
            + label {
                background-color: #ee6562;

                &:before {
                    color: #fff !important;
                }
            }

            &:checked + label {
                background-color: #bce954;

                &:before {
                    color: #fff !important;
                }
            }
        }

        &[switch='default']:checked + label {
            background-color: #a2a2a2;

            &:before {
                color: #fff !important;
            }
        }

        &[switch='success']:checked + label {
            background-color: #bce954;

            &:before {
                color: #fff !important;
            }
        }

        &[switch='warning']:checked + label {
            background-color: gold;

            &:before {
                color: #fff !important;
            }
        }
    }
}

//배너 banner
.banner {
    width: 100%;
    &.community {
        height: 25.6rem;
        background-color: $color-grayb-300;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        // 이미지가 원본비율 유지. 비율 안맞을 시 여백생길 수 있음.
    }
}
.mid-banner {
    width: 100%;
    height: 12rem;
    background-color: $color-grayb-400;
}
.search-banner {
    width: 100%;
    height: 9.2rem;
    background-color: #9e9e9e;
    margin-bottom: 1.4rem;
}

// 하단 gnb 네비게이션
.bottom-menu {
    position: fixed;
    bottom: 0;
    max-width: 72rem;
    width: 100%;
    border-radius: 1.6rem 1.6rem 0 0;
    padding: 1rem 2rem 3.4rem;
    background-color: #fff;
    box-shadow:
        0 1.5rem 1.2rem 0 rgba(0, 0, 0, 0.22),
        0 1.9rem 3.8rem 0 rgba(0, 0, 0, 0.3);
    ul {
        display: flex;
        justify-content: center;
        li {
            width: calc(100% / 4);
            text-align: center;
            a {
                display: block;
                width: 100%;
                font-size: 1.2rem;
                font-weight: 500;

                color: $color-grayb-300;
                img {
                    width: 2.4rem;
                    margin: 0 auto;
                    display: block;
                }
            }
            a {
                &.active {
                    color: $color-main-500;
                }
            }
        }
    }
}

.subscription-link-group {
    a {
        vertical-align: baseline;
    }
    .point-gr {
        font-size: 1.2rem;
        font-weight: 500;
        color: $color-grayb-600;
    }
    .point-g {
        font-size: 1.4rem;
        font-weight: 700;
        padding-left: 0.8rem;
    }
}

// 글쓰기 버튼
.writing-button-wrap {
    // width: 100%;
    // max-width: 72rem;
    position: fixed;
    bottom: 6.1rem;
    text-align: right;

    button {
        margin-right: 2rem;
        width: 4.8rem;
        height: 4.8rem;
        img {
            filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2))
                drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.12))
                drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.14));
        }
    }
    &.myhistory {
        margin-left: -1.2rem;
    }
}
html:has(.writing-button-wrap) {
    .content-wrap,
    .diary-reply-con {
        padding-bottom: 6rem;
    }
}
@media (max-width: 719px) {
    .writing-button-wrap {
        right: 0;
    }
}
@media (min-width: 720px) {
    .writing-button-wrap {
        right: initial;
        width: 72rem;
        bottom: 8rem;
    }
}
//댓글, input
.repl-wrap {
    max-width: 72rem;
    width: 100%;
    // margin-bottom: 1rem;
    // safe-area 영역같은거 없애고 생긴 공백
    min-height: 7.8rem;
    // position: fixed;
    // bottom: 0;
    background: #fff;
    display: flex;
    z-index: 10;
    gap: 0.8rem;
    align-items: center;
    padding: 1.6rem 2rem;
    // border-top: 0.02rem solid $color-grayb-500; 피그마
    border-top: 0.1rem solid $color-grayb-300;
    textarea {
        width: 100%;
        height: 2rem;
        min-height: 2rem;
        border-radius: 0.8rem;
        border: 0.1rem solid $color-grayb-500;
        padding: 1.2rem 1rem;
        font-size: 1.4rem;
        font-weight: 400;
        color: $color-text;
        &::placeholder {
            color: $color-grayb-500;
        }
        &:focus {
            border: 0.1rem solid $color-main-500;
            caret-color: unset;
        }
    }
    button {
        width: 2.8rem;
        height: 2.8rem;
        img {
            width: 2.8rem;
            height: 2.8rem;
        }
    }
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

// 오늘의 수첩 handbook
.handbook {
    max-width: 72rem;
    width: 100%;
    // position: fixed;
    // bottom: 70px;
    .hand-off {
        width: 100%;
        .handbook-wrap {
            width: 100%;
            height: auto;
            background-color: #fff;
            border-radius: 2.4rem 2.4rem 0 0;
            // box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.35);
            // padding: 16px 30px 24px;
            padding: 1.6rem 3rem 2.4rem 3rem;

            .handbook-btn {
                animation: moveUpDown 1s ease-in-out infinite;
            }
        }
    }
    .hand-on {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        .handbook-wrap {
            position: absolute;
            bottom: 0;
            width: 100%;
            max-height: 46.3rem;
            background-color: #fff;
            border-radius: 2.4rem 2.4rem 0 0;
            box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.35);
            padding: 2rem 3rem;
            .date-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 3rem;
                .date {
                    font-size: 1.8rem;
                    font-weight: 700;
                    color: #2ebf93;
                }
            }
            .content {
                .handbook-list {
                    margin-bottom: 3.2rem;
                    a {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 2rem;
                        font-weight: 800;
                        color: #000;
                        margin-bottom: 2rem;
                    }
                    .add-box {
                        background-color: $color-grayb-50;
                        padding: 4rem 6rem;
                        text-align: center;
                        border-radius: 1.6rem;
                        p {
                            font-size: 1.6rem;

                            font-weight: 700;

                            color: #bdbdbd;
                        }
                    }
                }
            }
        }
    }
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        strong {
            font-size: 1.6rem;
            font-weight: 700;
            color: #2ebf93;
            & + button {
                height: 3rem;
            }
        }
    }
}

//커뮤니티
.community-wrap {
    &.main {
        margin-top: 1.6rem;
        margin-bottom: 0;
    }
    .content-wrap {
        height: calc(100% - 8.15rem);
    }
}
// 게시판 리스트 (/popular, /neighbor, /myhistory, /community, /choice )
.list-article {
    > li {
        padding-top: 1.6rem;
        // 0.8에서 수정요청으로 변경 24.5.2
        padding-bottom: 1.6rem;
        border-bottom: 0.1rem solid #f8f8f8;
        .content {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 1rem;
            .info {
                width: calc(100% - 6.4rem);
                .subject {
                    font-size: 1.6rem;
                    font-weight: 400;
                    color: #202020;
                    overflow: hidden;
                    white-space: nowrap;
                    // text-overflow: ellipsis;
                    word-break: break-all;
                }
                .writer {
                    margin-bottom: 0.4rem;
                    font-size: 1.2rem;
                    font-weight: 500;

                    color: #a4b7af;
                    display: flex;
                    align-items: center;
                    gap: 0.2rem;
                    .bg-color {
                        display: block;
                        width: 1.2rem;
                        height: 1.2rem;
                        background-color: #de5792;
                        border-radius: 0.4rem;
                        margin-top: 0.2rem;
                    }
                }
            }
        }
        .util {
            ul {
                padding-top: 0.6rem;
                display: flex;
                align-items: center;
                line-height: 1.2;
                li {
                    font-size: 1.2rem;
                    font-weight: 400;
                    color: $color-grayb-600;
                    + li {
                        margin-left: 0.6rem;
                    }
                    &.date {
                        margin-right: auto;
                        font-weight: 400;
                    }
                    img {
                        vertical-align: -0.2rem;
                    }
                }
            }
        }
    }
    .community-wrap & {
        li {
            .content,
            .util {
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }
    }
}
body {
    &:has(.real-time-wrap) {
        .list-article {
            > li {
                margin-left: 2rem;
                margin-right: 2rem;
                &:has(.ad-area) {
                    & {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }

                .content,
                .util {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}
//게시판 리스트 있는 '내 활동 내역'
body:has(.adm-tabs-content) {
    .adm-tabs-header {
        border: none;
        .adm-tabs-tab-list {
            height: 4.8rem;
            margin-bottom: 1.6rem;
        }
    }
    .adm-tabs-content {
        padding: 0;
    }
}
.subject-list {
    font-size: 1.6rem;
    font-weight: 500;
    color: #202020;
    line-height: 1;
    .community-wrap & {
        line-height: 2.2rem;
    } //추가된 부분
    white-space: wrap;
    // text-overflow: ellipsis;
    word-break: break-all;
    .main & {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
}
.content {
    .info-main {
        width: 100%;
        .writer {
            @include caption-noto($color-grayb-600);
            margin-top: 0;
            // main.scss의 writer와 곂쳐서 커뮤니티 글 list에서는 0으로.
            margin-bottom: 0.4rem;
            display: flex;
            align-items: center;
            gap: 0.2rem;
            .bg-color {
                display: block;
                width: 1.2rem;
                height: 1.2rem;
                background-color: #de5792;
                border-radius: 0.4rem;
                margin-top: 0.2rem;
            }
        }
    }
}
.list-article {
    li {
        .content {
            .info-main {
                display: flex;
                // flex-direction: column;
                justify-content: space-between;
                height: 6.4rem;
                &:has(.subject-list) {
                    gap: 1rem;
                    height: initial;
                } //추가된 부분
                .writer {
                    margin: 0;
                    line-height: 1;
                }
            }
        }
    }
}

// 관심 동물 선택
.checkbox-list {
    .check-all {
        padding: 0.8rem 2rem 3rem;
        margin-bottom: 2.4rem;
        position: relative;
        border-bottom: 0.1rem solid #f5f5f5;
        label {
            font-size: 1.8rem;
            font-weight: 700;
            color: $color-grayb-600;
        }
        input[type='checkbox']:checked + label {
            color: #2ebf93;
        }
    }
    ul {
        padding: 0 2rem;
        li {
            & + li {
                margin-top: 1.2rem;
            }
            h3 {
                padding: 0.5rem;
                margin-bottom: 0.9rem;
            }
            .checkbox-button {
                display: inline-block;
            }
        }
    }
}

.check-tab-list {
    text-align: center;
    ul {
        padding: 0 2rem;
        li {
            & + li {
                margin-top: 1.2rem;
            }
            h3 {
                padding: 0.5rem;
                margin-bottom: 0.9rem;
            }
            .checkbox-tab {
                display: inline-block;
                & + .checkbox-tab {
                    margin-left: 0.4rem;
                }
            }
        }
    }
}

//리스트
.list-link-box {
    width: 100%;
    .setting & {
        .label-bg-g.pro {
            margin-left: 0.4rem;
        }
    }
    li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5.6rem;
        .faq-wrap & {
            height: 7rem;
        }
        font-size: 1.4rem;
        font-weight: 400;
        color: #000;
        border-bottom: 0.1rem solid $color-grayb-100;
        .tab-more &,
        .service-info &,
        .ext.social &,
        .setting & {
            // 상위 클래스명이되 다른페이지랑 중복되지 않는, 해당 페이지에만 있는 클래스명으로 해야함

            &:after {
                position: absolute;
                right: 0;
                content: '';
                background: url('../images/icon-arrow-right2.svg') no-repeat 50%
                    50%;
                width: 2.4rem;
                height: 2.4rem;
            }
        }

        &:first-of-type {
            margin-top: 0;
        }
        .service-info &,
        .ext.social & {
            // 상위 클래스명이되 다른페이지랑 중복되지 않는, 해당 페이지에만 있는 클래스명으로 해야함

            &:first-of-type:after {
                background: none;
            }
        }
        &:last-of-type {
            border-bottom: none;
        }
        &.version {
            .text {
                font-size: 1.4rem;
                color: $color-grayb-400;
            }
        }
        .faq-wrap & {
            & {
                border-bottom: 0.1rem solid $color-grayb-300;
                a {
                    padding: 1.6rem 0;
                    color: #000;
                    line-height: 1.4;
                }
            }
            &:after {
                position: absolute;
                right: 0;
                content: '';
                background: url('../images/ico_arrow_right_bk24.svg') no-repeat
                    50% 50%;
                width: 2.4rem;
                height: 2.4rem;
            }
        }
        &.general {
            button {
                border-radius: 1.6rem;
                border: 0.1rem solid #eee;
                padding: 0.8rem 1.6rem;
                background: $color-grayb-50;
                font-size: 1.2rem;
                font-weight: 500;
                color: #616161;
            }
        }
    }
    .link-box {
        li {
            font-weight: 400;
            cursor: pointer;
        }
        .point-g {
            font-weight: 400;
        }
        input[type='radio'] {
            position: absolute;
            left: -3000%;
        }
        input[type='radio'] + label {
            position: relative;
            width: 100%;
            font-size: 1.4rem;
            color: #000;
            font-weight: 300;
        }
        input[type='radio']:checked + label:after {
            position: absolute;
            right: 0;
            content: '';
            vertical-align: middle;
            box-sizing: border-box;
            transition: all 0.3s;
            display: inline-block;
            width: 2.4rem;
            height: 2.4rem;
            vertical-align: middle;
            background-image: url('../images/check-active.svg');
            background-repeat: no-repeat;
            background-size: 2rem 2rem;
            cursor: pointer;
        }
        input[type='radio']:checked + label {
            color: $color-main-600;
            font-weight: 500;
        }
    }
}

.wrap.tab-more {
    .section:last-child {
        .list-link-box {
            margin-bottom: 3rem;
        }
    }
}

// 그림자 박스
.round-box {
    border-radius: 1.6rem;
    box-shadow: 0 0.2rem 0.8rem 0 rgba(99, 99, 99, 0.2);
    padding: 2rem;
}
//프로필 수정
body:has(.profile-setting) {
    .wrap {
        padding-bottom: 0 !important;
    }
    .fix-bottom {
        .button-wrap {
            button {
                font-size: 1.8rem;
            }
        }
    }
}
.profile-setting {
    width: 100%;
    position: relative;
    .profile-box {
        padding-top: 1.1rem;
        .name {
            display: flex;
            justify-content: center;
            align-items: normal;
            gap: 0.4rem;
            p {
                font-size: 1.8rem;
                font-weight: 700;
            }
            button {
                img {
                    vertical-align: middle;
                }
            }
        }
        .level {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.4rem;
            p {
                font-size: 1rem;
                font-weight: 400;
            }
            .bg-color {
                display: block;
                width: 1.4rem;
                height: 1.4rem;
                background-color: #879ac1;
                border-radius: 0.4rem;
            }

            button {
                img {
                    vertical-align: middle;
                }
            }
        }
    }
    .util-box {
        padding: 1rem;
        border-radius: 1.6rem;
        background-color: $color-grayb-50;
        margin-bottom: 2.4rem;
        ul {
            display: flex;
            justify-content: center;
            li {
                padding-top: 0.6rem;
                padding-bottom: 0.3rem;
                width: calc(100% / 4);
                text-align: center;
                > div {
                    p {
                        font-size: 1.2rem;
                        font-weight: 400;
                        color: $color-grayb-500;
                    }
                }
                > p {
                    margin-top: 0.8rem;
                    font-size: 1.8rem;
                    font-weight: 700;
                }
                .visit-count,
                .repl-count,
                .write-count,
                .interest-count {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: 1.6rem 1fr;
                    place-items: center;
                    img {
                        width: 1.6rem;
                        height: auto;
                    }
                }
            }
        }
    }
    .round-box {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        margin-bottom: 2.4rem;
        padding: 1.1rem 1rem 1rem 1rem;
        &:last-of-type {
            margin-bottom: 0;
        }
        .title-box {
            padding-left: 0.5rem;
            padding-right: 1rem;
            height: 4.2rem;
        }
        .location-wrap {
            display: flex;
            flex-direction: column;
            gap: 0.8rem;
            .location-box {
                padding-top: 0.4rem;
                padding-left: 0.8rem;
                padding-bottom: 0.8rem;
                display: flex;
                gap: 0.8rem;
                .my-location {
                    padding: 0.8rem 1.2rem;
                    display: inline-block;
                    border-radius: 3.2rem;
                    background-color: $color-main-50;
                    @include body-bold($color-main-600);
                }
                .my-location-empty {
                    @include body-bold($color-grayb-500);
                    border: 0.1rem dashed $color-grayb-500;
                    border-radius: 3.2rem;
                    background-color: $color-grayb-50;
                    display: inline-block;
                    padding: 0.8rem 2.4rem;
                }
            }
        }
        .checkbox-list {
            padding-bottom: 0;
            ul {
                padding: 0;
                li {
                    + li {
                        margin-top: 2.4rem;
                    }
                    h3 {
                        margin-bottom: 0;
                        padding-top: 0.8rem;
                        padding-left: 1.3rem;
                        font-size: 1.4rem;
                        font-weight: 700;
                    }
                    .checkbox-button {
                        margin-top: 0.4rem;
                        + .checkbox-button {
                            margin-left: 0.4rem;
                        }
                        input[type='checkbox']:checked + label {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 719px) {
    .button-wrap {
        padding-top: 2.4rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 0.2rem;
        // indicator영역에 버튼 짤림방지
        .profile-setting & {
            padding-bottom: 0.2rem;
            // indicator영역에 버튼 짤림방지
        }
    }
}
@media (min-width: 720px) {
    .button-wrap {
        .profile-setting & {
            padding-bottom: 0;
        }
    }
}
// 더보기 첫화면 : 무료유저 view
.tab-more {
    background-color: #fff;
    .section:nth-of-type(4) {
        padding: 2.4rem 2rem;
    }

    .info-box {
        text-align: center;
        .text-box:first-child {
            font-size: 1.6rem;
            font-weight: 600;
        }
        &.membership-has {
            .text-box:first-child {
                width: 100%;
                height: 6.4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f1fcf6;
                border-radius: 1.6rem;
                p:first-child {
                    font-size: 1.6rem;
                    color: #2ebf93;
                }
                p:last-child {
                    margin-top: 0.3rem;
                    font-size: 1.2rem;
                    color: #000;
                    font-weight: 400;
                }
            }
        }
        &.membership-empty {
            .text-box:first-child {
                p:first-child {
                    width: 100%;
                    height: 6.4rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #9e9e9e;
                    background-color: $color-grayb-50;
                    border-radius: 1.6rem;
                }
            }
        }
        .text-box:last-child {
            font-size: 1.2rem;
            span:first-child {
                color: #bdbdbd;
                font-weight: 300;
            }
            span:last-child {
                color: #2ebf93;
                font-weight: 700;
            }
        }
    }
}

.checkbox-list {
    .checkbox-button-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 0.4rem;
    }
}

// 셀렉트
select {
    width: 100%;
    border: none;
    border-bottom: 0.1rem solid $color-grayb-600;
    background: url('../images/icon-arrow-bottom.svg') no-repeat 97% 50%/1.5rem
        auto;
}
//달력
.calendar-wrap {
    position: relative;
    z-index: 9;
    .calendar-header {
        width: 100%;
        padding: 0 2rem;
        display: flex;
        justify-content: space-between;
        .month {
            button {
                font-size: 1.8rem;

                font-weight: 700;

                color: #2ebf93;
                img {
                    vertical-align: bottom;
                }
            }
        }
    }
    .calendar-header2 {
        position: relative;
        background-color: #fff;
        .month {
            button {
                font-size: 1.8rem;

                font-weight: 700;

                color: #2ebf93;
                img {
                    vertical-align: bottom;
                }
            }
        }
    }
}
.week-calendar {
    width: 100%;
    text-align: center;
    img {
        width: 100%;
        object-fit: cover;
    }
}
.month-calendar {
    width: 100%;
    height: 100%;
    text-align: center;
    // margin-bottom: 7px;
    .cmp-line-calendar-date-value-circle {
        font-size: 1.4rem;
        font-weight: 500;
    }
    .month-calendar-box {
        position: absolute;
        z-index: 99;
        // top: 103%;
        left: 50%;
        width: 100%;
        // height: 100vh;
        transform: translate(-50%, 0);
        // background-color: rgba(0, 0, 0, 0.30);
        img {
            width: 100%;
            object-fit: cover;
        }
    }
}
.month-memory-wrap {
    margin-top: 2.4rem;
    margin-left: 2.4rem;
    height: 5.6rem;
    gap: 0.8rem;
    padding: 0.4rem 0 10rem 0;
    color: rgba(189, 189, 189, 1);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.907rem;
    .this-month-wrap {
        height: 2.2rem;
        gap: 0.8rem;
        .this-month-text {
            font-size: 1.6rem;
            font-weight: 700;
            color: rgba(46, 191, 147, 1);
            line-height: 2.179rem;
        }
    }
    .util-now-wrap {
        height: 2.2rem;
        gap: 0.8rem;
        .util-now-text {
            font-size: 1.6rem;
            font-weight: 700;
            color: rgba(132, 123, 233, 1);
            line-height: 2.179rem;
        }
    }
}
.month-stat-wrap {
    gap: 1.2rem;
    padding-bottom: 7rem;
    .month-stat {
        padding-left: 4.25rem;
        padding-right: 4.25rem;
        gap: 1.2rem;
        height: 4.4rem;
        .month-stat-label-wrap {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            // height: 1.9rem;
            color: rgba(158, 158, 158, 1);
            font-size: 1.4rem;
            text-align: center;
            // .budget-wrap {
            //   flex: 1;
            //   padding: 0 3rem 0 4rem;
            //   gap: 0.2rem;
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            // }
            // .expense-wrap {
            //   flex: 1;
            //   padding: 0 3.9rem 0 3.9rem;
            //   text-align: center;
            //   gap: 0.2rem;
            // }
            // .sum-wrap {
            //   flex: 1;
            //   padding: 0 3.9rem 0 3.9rem;
            //   text-align: center;
            //   gap: 0.2rem;
            // }
        }
        .month-stat-value-wrap {
            // height: 1.9rem;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            text-align: center;
            margin-top: 0.4rem;
            color: rgba(158, 158, 158, 1);
            font-size: 1.4rem;
            > div:nth-of-type(1) {
                color: rgba(132, 123, 233, 1);
            }
            > div:nth-of-type(2) {
                color: rgba(46, 191, 147, 1);
            }
            > div:nth-of-type(3) {
                color: rgba(0, 0, 0, 1);
            }
            // .budget-wrap {
            //   flex: 1;
            //   padding: 0 3rem 0 4rem;
            //   text-align: center;
            //   color: rgba(132, 123, 233, 1);
            //   gap: 1rem;
            // }
            // .expense-wrap {
            //   flex: 1;
            //   padding: 0 3.9rem 0 3.9rem;
            //   text-align: center;
            //   color: rgba(46, 191, 147, 1);
            //   gap: 1rem;
            // }
            // .sum-wrap {
            //   flex: 1;
            //   padding: 0 3.9rem 0 3.9rem;
            //   text-align: center;
            //   color: rgba(0, 0, 0, 1);
            //   gap: 1rem;
            // }
        }
    }
}
.budget-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 0.2rem;
    }
}
.month-switch-wrap {
    padding: 0 0.8rem 0 2rem;
    display: flex;
    justify-content: space-between;
    .btn-year {
        display: flex;
        align-items: center;
        margin: 2.4rem 0;
        height: 4rem;
        color: $color-main-600;
        font-size: 1.8rem;
        font-weight: 600;
    }
}

.cmp-simple-calendar-date-label {
    width: 3.6rem;
    height: 3.6rem;
    object-fit: cover;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 1.2rem;
    background-position: center;
    /* background-image: url("https://via.placeholder.com/700"); */
    &.modal-font-size {
        font-size: 1.4rem;
        font-weight: 500;
    }
}

.faq-view {
    height: 100%;
    background-color: $color-grayb-50;
    .title-wrap {
        padding-top: 1.8rem;
        padding-left: 2rem;
        padding-bottom: 2rem;
        border-bottom: 0.1rem solid #e0e0e0;
        .subject {
            margin: 1rem 0;
            font-size: 1.6rem;
            font-weight: 700;

            color: #000;
        }
        .date {
            font-size: 1.2rem;
            color: #a4b7af;
        }
    }
    .content-wrap {
        padding: 1.6rem 2rem 0 2rem;
        font-size: 1.4rem;
        color: #000;
        font-weight: 400;
        line-height: 1.5;
        p {
            margin-top: 4rem;
        }
        .after-editer-api {
            margin-left: 2rem;
            ul {
                li {
                    list-style-type: disc;
                }
            }
            ol {
                li {
                    list-style-type: decimal;
                }
            }
        }
        .notice-view-img {
            padding: 1.6rem 0;
        }
        .text-group.faq {
            p {
                margin: 0;
            }
            p {
                padding-bottom: 2rem;
            }
        }
    }
    &.faq {
        .title-wrap {
            padding: 2.4rem 2rem 2rem 2rem;
            .subject {
                margin: 0;
            }
        }
    }
}

// S: 모든 페이지의 프로필 이미지 css는 common에서 관리
.photo {
    width: 6.4rem;
    height: 6.4rem;
    flex: 0 0 6.4rem;
    border-radius: 0.8rem;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

img.profile {
    width: 100%;
    height: 100%;
    &.contain {
        object-fit: contain;
    }
    // 이미지가 원본비율을 유지한다
    &.fill {
        object-fit: fill;
    }
    &.cover {
        object-fit: cover;
    }
}

.profile-img-box {
    border-radius: 70%;
    overflow: hidden;
    // 페이지별로 다른 img size를 .profile-wrap.profile-img-{imgSize}로 관리

    .profile-wrap.profile-img-100 & {
        width: 10rem;
        height: 10rem;
    }
    .profile-wrap.profile-img-20 & {
        width: 2rem;
        height: 2rem;
    }
    .profile-wrap.profile-img-24 & {
        width: 2.4rem;
        height: 2.4rem;
    }
    .profile-wrap.profile-img-32 & {
        width: 3.2rem;
        height: 3.2rem;
    }
    .profile-wrap.profile-img-40 & {
        width: 4rem;
        height: 4rem;
    }
    .profile-wrap.profile-img-42 & {
        width: 4.2rem;
        height: 4.2rem;
    }
    .profile-wrap.profile-img-56 & {
        width: 5.6rem;
        height: 5.6rem;
    }
}

.profile-img-box-sqare {
    border-radius: 0.8rem;
    overflow: hidden;
    .profile-wrap.profile-img-64 & {
        width: 6.4rem;
        height: 6.4rem;
    }
}
.profile-wrap.profile-img-100 {
    position: relative;
    margin: 0 auto;
    width: 10rem;
    height: 10rem;
    .btn-img-add {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: -0.3rem;
        right: 0.5rem;
        width: 2.8rem;
        height: 2.8rem;
        // background:rgba(255, 255, 255, 0.70) ;
        background: #fff;
        border-radius: 100%;
        line-height: 2.8;
        &.default {
            background: #fff;
        }
    }
}

.profile-horizental {
    display: flex;
    align-items: center;
    &.profile-img-20 {
        gap: 0.4rem;
    }
    &.profile-img-24 {
        gap: 0.4rem;
    }
    &.profile-img-40 {
        gap: 1.2rem;
    }
    &.profile-img-42 {
        gap: 1.6rem;
    }
    &.profile-img-32 {
        gap: 1.6rem;
    }
    &.profile-img-56 {
        gap: 1.6rem;
    }
}
.profile-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    .diary-reply-con & {
        margin-top: 1.6rem;
    }
    .wallet-view & {
        margin-top: -2.5rem;
    }
}

.more-profile-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.2rem 0 2rem 0;
    .more-profile-set {
        display: flex;
        gap: 1.6rem;
    }
    .more-profile-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .writer-info-wrap {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            margin-bottom: 0.4rem;
            .nick-name {
                font-size: 1.8rem;
                font-weight: 700;
            }
            .level {
                display: flex;
                align-items: center;
                gap: 0.4rem;
                p {
                    font-size: 1rem;
                    font-weight: 400;
                }
            }
        }
        .user-count-info {
            li {
                display: inline-block;
                font-weight: 500;
                font-size: 1.2rem;
                color: $color-grayb-600;
                span:last-child {
                    margin: 0 0.5rem 0 0.2rem;
                }
            }
        }
    }
}
// E: 모든 페이지의 프로필 이미지 css는 common에서 관리

// S: write pages elements 쓰기페이지 관련된 요소들 (일지쓰기, 가계부쓰기, 반려동물 등록)
.write-input-lis {
    padding: 2.4rem 0 1.1rem 0;
    width: 100%;
}
.write-input-list,
.animal-add {
    .item {
        display: grid;
        gap: 1.6rem;
        grid-template-columns: 4.2rem 1fr;
        grid-template-rows: 4.2rem;
        padding-right: 0.8rem;
        margin: 0 2rem;
        font-size: 1.4rem;
        font-weight: 500;
        color: $color-grayb-600;

        + .item {
            padding-top: 2.4rem;
        }
        &.select {
            .input-select {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 4.2rem;
                font-size: 1.4rem;
                font-weight: 500;
                color: $color-grayb-600;
                border-bottom: 0.1rem solid $color-grayb-600;
                cursor: pointer;
            }
        }
        select {
            height: 4.2rem;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: $color-grayb-600;
            background: url('../images/icon-arrow-bottom.svg') no-repeat 97%
                50%/2.4rem auto;
            border-bottom: 0.1rem solid $color-grayb-600;
        }
        p {
            &.title {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.4rem;
                font-weight: 500;
                color: $color-grayb-600;
            }
            &.hidden {
                visibility: hidden;
            }
        }
    }

    .writing-content {
        margin-top: 1rem;
        padding: 0 2rem;
        .title-input,
        .writing-textarea {
            padding: 0 0.8rem;
        }
        .title-input {
            input {
                width: 100%;
                font-weight: 700;
                color: #000;
                &::placeholder {
                    color: #bdbdbd;
                }
            }
        }
        .writing-textarea {
            textarea {
                font-weight: 400;
                color: #616161;
                &::placeholder {
                    color: #bdbdbd;
                }
            }
        }
        .img-wrap {
            padding-top: 1rem;
        }
    }
    .input-box {
        width: 100%;
        input {
            @include body-me($black00);
            width: 100%;
            height: 4.2rem;
            // 기본
            &::placeholder {
                color: $color-grayb-600 !important;
            }
            /* input에 값 없을 때 */
            &:placeholder-shown {
                border-bottom: 1px solid $color-grayb-600;
            }
            /* input에 값 있을 때 */
            &:not(:placeholder-shown) {
                color: $black00;
                border-bottom: 1px solid $black00;
            }
            // 포커스될 때. 이걸 :not(:placeholder-shown)뒤에 넣어야 우선순위 높아져서 적용됨.
            &:focus {
                caret-color: $color-main-500;
                border-bottom: 2px solid $color-main-500;
            }
        }
        span {
            font-size: 1.4rem;
            font-weight: 500;
            color: #000;
        }
    }
    .weight {
        .input-box {
            position: relative;
            span {
                position: absolute;
                right: 0.5rem;
                bottom: 1rem;
            }
        }
    }
    .gender {
        .radio-wrap {
            display: flex;
            gap: 8rem;
            .ico-radio {
                margin-right: 0.8rem;
            }
        }
    }
}
.writing-content,
.community-writing {
    .title-input {
        input {
            width: 100%;
            font-weight: 700;
            color: #000;
            &:focus {
                border: none !important;
            }
        }
    }
}
.user-box {
    padding: 0.6rem 2rem 0 2rem;
    .user-writer-wrap,
    .user-editer-wrap {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        padding: 0.6rem 1rem;
        font-size: 1.4rem;
        font-weight: $color-grayb-600;
        .nick-name {
            color: $color-main-600;
        }
        img {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 0.4rem;
        }
        .title {
            color: $color-grayb-600;
            font-weight: 400;
            font-size: 1.4rem;
        }
    }
    .user-editer-wrap {
        .editer-grpup {
            display: flex;
            gap: 0.8rem;
            .item {
                display: flex;
                align-items: center;
            }
        }
    }
}

.account-book-write,
.diary-writing,
.community-writing,
.animal-add {
    input[type='text'] {
        &::placeholder {
            color: $color-grayb-400;
        }
        &:focus {
            border-bottom: 0.2rem solid $color-main-500;
            caret-color: $color-main-500;
        }
    }
    .writing-textarea {
        textarea {
            min-height: 20rem;
            height: auto;
            max-height: 70rem;
        }
        .img-wrap {
            .adm-image {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    @media (min-width: 500px) {
        .writing-textarea {
            textarea {
                min-height: 50rem;
                height: auto;
                max-height: 70rem;
            }
        }
    }
}

// E: write pages elements 쓰기페이지 관련된 요소들 (일지쓰기, 가계부쓰기, 반려동물 등록)

.ad-area-cph {
    display: flex;
    align-items: center;
    margin-top: 0.4rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 0;
    width: calc(100% - 4rem);
    aspect-ratio: 2.8 / 1;
    // height:12rem;
    // border:1px solid peru;
    border-radius: 1.6rem;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.ad-area {
    width: 100%;
    height: 1px !important; //요청에 의한 높이 강제 추가 나중에 삭제해도됨
    &.h92 {
        //background-color: $color-grayb-500;
        background-color: #ffffff; //요청에 의한 배경투명 강제 추가 나중에 삭제해도됨
        height: 9.2rem;
    }
    &.h120 {
        //background-color: $color-grayb-400;
        background-color: #ffffff; //요청에 의한 배경투명 강제 추가 나중에 삭제해도됨
        height: 12rem;
        .home & {
            margin-top: 5rem;
            margin-bottom: 1.8rem;
        }
    }
    &.h160 {
        //background-color: $color-grayb-500;
        background-color: #ffffff; //요청에 의한 배경투명 강제 추가 나중에 삭제해도됨
        height: 16rem;
    }
    &.h256 {
        //background-color: $color-grayb-300;
        background-color: #ffffff; //요청에 의한 배경투명 강제 추가 나중에 삭제해도됨
        height: 25.6rem;
    }
    .notice-main &,
    .notice-event & {
        position: fixed;
        bottom: 0;
        margin-bottom: 3.4rem;
    }
    &.search {
        position: fixed;
        bottom: 11.1rem;
    }
}
.adsbygoogle {
    height: 1px !important;
}
@media (min-width: 720px) {
    .ad-area {
        &.search {
            bottom: 13.1rem;
        }
    }
}
.empty-wrap-only {
    position: relative;
    height: calc(100% - 7.4rem);
}
.empty-con {
    text-align: center;
    .empty-wrap-ad & {
        margin-top: 8.5rem;
    }
    .empty-wrap-only & {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    p:nth-of-type(1) {
        font-size: 1.4rem;
        font-weight: 600;
        color: $color-main-500;
    }
    p:nth-of-type(2) {
        margin-top: 0.6rem;
        font-size: 1.2rem;
        font-weight: 400;
        color: $color-grayb-400;
    }
}

// 정렬

.align {
    display: flex;
}
.align.both {
    justify-content: space-between;
}
.align.right {
    justify-content: flex-end;
}
.align.center {
    justify-content: center;
}
.align.vt {
    align-items: flex-start;
}
.align.vm {
    align-items: center;
}
.align.vb {
    align-items: flex-end;
}
.align.direction-col {
    flex-direction: column;
}
.align.direction-row {
    flex-direction: row;
}
.txt-center {
    text-align: center;
}
.txt-right {
    text-align: right;
}
.txt-left {
    text-align: left;
}
// 여백

.ml-20 {
    margin-left: 2rem;
}
.pl-33 {
    padding-left: 3.3rem;
}
.pr-32 {
    padding-right: 3.2rem;
}
.mt-16 {
    margin-top: 1.6rem;
}
.mt-18 {
    margin-top: 1.8rem;
}
.mt-22 {
    margin-top: 2.2rem;
}
.mt-24 {
    margin-top: 2.4rem;
}
.mt-28 {
    margin-top: 2.8rem;
}
.mt-32 {
    margin-top: 3.2rem;
}
.mt-52 {
    margin-top: 5.2rem;
}
.mb-16 {
    margin-bottom: 1.6rem;
}
.mb-20 {
    margin-bottom: 2rem;
}
.mb-24 {
    margin-bottom: 2.4rem;
}
.mb-32 {
    margin-bottom: 3.2rem;
}
.mb-48 {
    margin-bottom: 4.8rem;
}
.mb-110 {
    margin-bottom: 11rem;
}

.ma-lr-20 {
    margin: 0 2rem;
}
.ma-tb-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
}
.ma-tb-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
}
.pd-tb-11 {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
}
.pd-tb-10 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.pd-tb-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
}
.pd-lr-29 {
    padding-left: 2.9rem;
    padding-right: 2.9rem;
}
.pd-20 {
    padding-left: 2rem;
    padding-right: 2rem;
}
.pd-25 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.p-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
}
.pd-18 {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
}
.pl-16 {
    padding-left: 1.6rem;
}
.pl-18 {
    padding-left: 1.8rem;
}
.pr-17 {
    padding-right: 1.7rem;
}
.pl-22 {
    padding-left: 2.2rem;
}
.pl-33 {
    padding-left: 3.3rem;
}
.pr-32 {
    padding-right: 3.2rem;
}
.pd-lr-18 {
    padding: 0 1.8rem;
}
.pd-lr-30 {
    padding: 0 3rem;
}
.pt-4 {
    padding-top: 0.4rem;
}
.pt-8 {
    padding-top: 0.8rem;
}
.pt-12 {
    padding-top: 1.2rem;
}
.pt-16 {
    padding-top: 1.6rem;
}
.pt-24 {
    padding-top: 2.4rem;
}
.pl-10 {
    padding-left: 1rem;
}
.pl-12 {
    padding-left: 1.2rem;
}
.pr-12 {
    padding-right: 1.2rem;
}
.pr-16 {
    padding-right: 1.6rem;
}
.pr-18 {
    padding-right: 1.8rem;
}
.pr-8 {
    padding-right: 0.8rem;
}
.pd-lr-8 {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}
.pb-2 {
    padding-bottom: 0.2rem;
}
.pb-4 {
    padding-bottom: 0.4rem;
}
.pb-8 {
    padding-bottom: 0.8rem;
}
.pb-10 {
    padding-bottom: 1rem;
}
.pb-14 {
    padding-bottom: 1.4rem;
}
.pb-16 {
    padding-bottom: 1.6rem;
}
.pb-18 {
    padding-bottom: 1.8rem;
}
.pb-22 {
    padding-bottom: 2.2rem;
}
.pb-23 {
    padding-bottom: 2.3rem;
}
.pb-24 {
    padding-bottom: 2.4rem;
}
.pb-27 {
    padding-bottom: 2.7rem;
}
.pb-65 {
    padding-bottom: 6.5rem;
}
.pb-34 {
    padding-bottom: 3.4rem;
    // indicator-area
}

.br-0 {
    border: 0 !important;
}

.display-none {
    display: none;
}

// 글쓰기

.writing-textarea {
    .img-wrap {
        .img-count {
            text-align: right;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #a4b7af;
            margin-bottom: 1.6rem;
            .count,
            .total {
                color: #a4b7af;
            }
        }
        .img-list {
            width: 100%;
            margin-bottom: 1.6rem;
            overflow-x: auto;
            overflow-y: hidden;
            ul {
                display: flex;
                gap: 0.8rem;
                min-width: 10rem;
                li {
                    position: relative;
                    > div {
                        width: 7rem;
                        height: 7rem;
                    }
                    .img-delete {
                        position: absolute;
                        top: 0;
                        right: 0;
                        img {
                            vertical-align: top;
                        }
                    }
                }
            }
        }
    }
}
.tag-box {
    display: flex;
    gap: 0.8rem;
    max-width: 72rem;
    overflow-x: auto;
    margin: 1.6rem 0 0 2rem;
}
.size-span {
    white-space: pre;
    // height: 1em;
    display: inline-block;
    font-size: 1.4rem;
    box-sizing: border-box;
    position: relative;
    opacity: 0;
    min-width: 8.5rem;
    height: 3.5rem;
    padding: 0.8rem 1.2rem;
}
.input-wrapper {
    background: $color-grayb-50;
    text-align: center;
    width: fit-content;
    border-radius: 3.2rem;
    position: relative;
    font-size: 1.4rem;
    display: flex;
    text-overflow: ellipsis;
    input {
        border: none;
        width: 100%;
        height: 100%;
        background: none;
        color: $color-grayb-500;
        top: 0;
        left: 0;
        font-size: 1.4rem;
        // padding: 0.8rem 1.2rem;
        position: absolute;
        box-sizing: border-box;
        text-align: center;
        &:focus {
            border: none !important;
        }
        &::placeholder {
            color: $color-grayb-500;
        }
        &.input-tag {
            &::placeholder {
                color: $color-grayb-500;
            }
        }
        &.input-tag:disabled,
        &.active {
            color: $color-main-600;
            background-color: $color-main-50;
            font-weight: 700;
            border-radius: 3.2rem;
            &::placeholder {
                color: $color-main-600;
                font-weight: 700;
            }
        }
    }
}
.tag-add-wrap {
    display: flex;
    align-items: center;
    width: fit-content;
    > button {
        width: 2.4rem;
        height: 2.4rem;
    }
    .btn-tag-add {
        padding-right: 0.8rem;
    }
    img {
        width: 2.4rem;
        height: 2.4rem;
        max-width: initial;
    }
}
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    &.area-delete {
        padding: 0 1.6rem 0 2.4rem;
        height: 4.8rem;
        font-size: 1.8rem;
        font-weight: 700;
        border-width: 0.1rem;
        border-style: solid;
        border-radius: 0.8rem;
        border-color: $color-grayb-50;
        &:after {
            content: '';
            width: 2.4rem;
            height: 2.4rem;
            background: url('../images/ico_delete_grayT500.svg') no-repeat 0 0 /
                contain;
            // background-image: url('../images/checkbox-off.svg');
        }
    }
    &.area-add {
        padding: 0 5.6rem;
        height: 4.8rem;
        border: 0.05rem dashed $color-grayb-500;
        border-radius: 0.6rem;
    }

    &.grayt100 {
        background-color: $color-grayb-50;
        color: $color-grayb-500;
    }
}

.toggle-wrap {
    display: inline-block;
    padding: 1.2rem 0;
    .tgl {
        display: none;

        & + .tgl-btn {
            outline: 0;
            display: block;
            width: 4rem;
            height: 2.4rem;
            position: relative;
            cursor: pointer;
            user-select: none;
            background: #bdbdbd;
            border-radius: 3.9rem;
            padding: 0.2rem;
            transition: 0.3s;
            &:after {
                content: '';
                position: absolute;
                width: 2rem;
                height: calc(100% - 0.4rem);
                left: calc(100% - 3.8rem);
                border-radius: 50%;
                background: #fff;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
                transition: all 0.3s;
            }
        }
        &:checked + .tgl-btn {
            background: #40df9f;
            &:after {
                margin-right: 0.2rem;
                left: calc(50% - 0.2rem);
            }
        }
    }
}

.neighbor-add {
    padding: 1.3rem 0;
    width: calc(100% - 4rem);
    font-size: 1.6rem;
    font-weight: 700;
    border-radius: 0.8rem;
    color: $color-grayb-500;
    background: $color-grayb-50;
    &::before {
        content: '';
        padding-right: 0.8rem;
        width: 2.4rem;
        height: 2.4rem;
        background-image: url('../images/ico_neighbor_add.svg');
        background-repeat: no-repeat;
    }
    &.--active {
        color: $color-main-600;
        background: $color-main-50;
        &::before {
            content: '';
            padding-right: 0.8rem;
            width: 2.4rem;
            height: 2.4rem;
            background-image: url('../images/ico_neighbor_add_active.svg');
            background-repeat: no-repeat;
        }
    }
}

// S : 공통으로 들어가는 곳 main: bottom-sheet, /community/search,

.diary-wrap {
    &.scroll {
        height: 40rem;
        overflow: auto;
    }
    .calendar-inline-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.4rem 3rem 0.8rem 3rem;
        color: $color-main-600;
        font-size: 1.8rem;
        font-weight: 600;
        background-color: $white;
        .title {
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 700;
            color: rgba(46, 191, 147, 1);
        }
    }
    .title-wrap {
        .btn-arrow-right {
            position: relative;
            width: 3rem;
            height: 3rem;
            &::after {
                content: '';
                background: url('../images/icon-arrow-right2.svg') no-repeat 50%
                    50%;
                width: 3rem;
                height: 3rem;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    &.content {
        .title-wrap {
            margin-bottom: 1.6rem;
            .search & {
                margin-bottom: 0.8rem;
            }
            h2 {
                margin-bottom: 0.8rem;
            }
        }
        .comn-box {
            width: 100%;
            height: 10rem;
            display: grid;
            background: $color-grayb-50;
            border-radius: 1.6rem;
            &:has(.empty-con) {
                display: flex;
                justify-content: center;
                align-items: center;
                .empty-con {
                    color: $color-grayb-400;
                    font-size: 1.6rem;
                    font-weight: 700;
                }
            }
        }
        .info {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            span {
                @include caption-noto($color-grayb-400);
                &:has(img) {
                    @include caption-noto($color-grayb-300);
                }
            }
            .nick-name {
                margin-left: 0.2rem;
            }
        }
        .btn-more {
            // position:relative;
            margin: 0 3.3rem 0 3.2rem;
            padding: 1rem 0;
            width: calc(100% - 6.5rem);
            font-size: 1.4rem;
            font-weight: 500;
            border: 1px solid $color-grayb-500;
            border-radius: 0.6rem;
        }
    }

    // 일지
    .log-wrap {
        &.main {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                display: inline-block;
                width: 3rem;
                height: 1rem;
                background: #ccc;
                border-radius: 2rem;
            }
        }
        .bg-content {
            padding: 1.2rem 1.2rem 0.8rem;
        }

        .log-box {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            margin-bottom: 1.6rem;

            .log-text-box {
                // 메인팝업
                padding: 1.2rem;
                h3 {
                    line-height: 1;
                    font-weight: 700;
                    color: #000;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    word-break: break-all;
                    line-height: 1.3;
                }
                .text {
                    margin-top: 1.2rem;
                    font-size: 1.4rem;
                    line-height: 1.4;
                    font-weight: 400;
                    color: $color-grayb-600;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    word-break: break-all;
                }
            }

            &:has(.log-img) {
                position: relative;
                .log-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 12rem;
                    object-fit: cover;
                    overflow: hidden;
                    border-radius: 1.6rem;
                }
                .log-text-box {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 99;

                    .text {
                        color: rgba(238, 238, 238, 0.4);
                    }
                }
                p.info {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    z-index: 99;
                    span {
                        color: $color-grayb-300;
                        font-size: 1.2rem;
                        font-weight: 400;
                    }
                    .nick-name {
                        margin-left: 0.2rem;
                    }
                }
                h3 {
                    color: #fff;
                }
            }
        }
    }

    // 가계부
    .account-wrap {
        margin-bottom: 1.6rem;
        .title-s {
            margin-bottom: 0.8rem;
        }
        .account-box {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            margin-bottom: 1.6rem;
            ul {
                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 1.95rem 0 2.05rem;
                    &:first-child {
                        margin-top: 1.6rem;
                    }
                    + li {
                        margin-top: 0.8rem;
                    }
                    .profile {
                        margin-right: 0.4rem;
                    }
                    .label-bg-g {
                        margin-right: 0.4rem;
                    }
                    .account-con {
                        @include body-me($black);
                    }
                    .account-price {
                        @include body-me($color-main-600);
                        margin-left: auto;
                    }
                }
            }
        }
    }
}
// E : 공통 main: bottom-sheet, /community/search,

// S : 공통 /wallet , /community/search/wallet
.wallet-ul {
    margin-bottom: 2.4rem;
    > li {
        display: flex;
        gap: 2rem;
        align-items: center;
        padding: 1.6rem 0;
        &:last-of-type {
            padding-bottom: 0;
        }
        + li {
            border-top: 0.1rem solid #f5f5f5;
        }
        .profile {
            width: 4rem;
            flex: 0 0 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
                color: $color-main-500;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
        .content-box {
            width: 100%;
            .people-info-wrap {
                .info {
                    display: flex;
                    gap: 0.4rem;
                    span {
                        font-size: 1.2rem;
                        font-weight: 500;
                        color: $color-grayb-400;
                    }

                    img {
                        vertical-align: middle;
                    }
                }
            }
            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0.8rem;
                font-size: 1.6rem;
                font-weight: 700;
                h3 {
                    font-size: 1.6rem;
                    font-weight: 700;
                    width: 100%;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    word-break: break-all;
                }
                button {
                    display: flex;
                    align-items: center;
                }
            }
            .content {
                .text {
                    font-size: 1.4rem;
                    font-weight: 700;
                }

                .price-box {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.4rem;
                    align-items: center;
                    .label-bg-g {
                        margin: 0;
                        font-size: 1.2rem;
                        font-weight: 500;
                    }
                    .payment {
                        padding: 0.4rem;
                        font-size: 1.2rem;
                        font-weight: 500;
                        color: #9e9e9e;
                    }
                    .price {
                        margin-left: auto;
                        display: flex;
                        gap: 0.2rem;
                        align-items: center;
                        strong {
                            font-size: 1.8rem;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                        span {
                            font-size: 1.4rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            color: $color-grayb-600;
                        }
                    }
                }
            }
            .tag {
                margin-top: 0.8rem;
                ul {
                    display: flex;
                    gap: 0.6rem;
                    li {
                        a {
                            font-family: GmarketSansMedium;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -0.06rem;
                            color: #2ebf93;
                        }
                    }
                }
            }
        }
    }
}

// E : 공통 /wallet , /community/search/wallet
.full-size {
    width: 100%;
}
.bg-white {
    background-color: #fff;
}

.ad-area-cph {
    .adm-image {
        .adm-image-img {
            object-fit: cover !important;
        }
    }
}

// [data-rsbs-scroll] {
//   overflow: hidden;
// }
//살리면 main 오늘의수첩 스크롤 안됨

.position-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.position-y {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.position-xy {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ql-snow {
    padding: 0;
    .ql-editor {
        padding: 0;
        blockquote {
            border: initial;
            padding-left: 0;
        }
    }
}

.slick-prev {
    z-index: 99;
    // 슬라이드 화살표 버튼이 header를 넘어가서 99로 낮춤
}

// S: content title padding
.wrap {
    &.note-share-give {
        padding-top: 4.8rem;
        // height: calc(100vh - 4.8rem);
        .title-m {
            line-height: 1.4;
            padding-bottom: 1.4rem;
        }
    }
    &.data-export {
        .mail-box {
            .title {
                padding-top: 5.6rem;
            }
        }
    }
    &.data-import {
        .mail-wrap {
            .mail-box {
                padding-top: 4.8rem;
                .title {
                    padding-top: 5.6rem;
                }
            }
        }
    }
}

// E: content title padding

.color-grayt-500 {
    @include body-me($color-grayb-600);
}
.fail {
    color: $color-danger-500;
}
.adm-tabs-tab {
    font-weight: 500;
    margin: 0 !important;
    width: 100% !important;
    text-align: center;
}
.adm-tabs-tab[aria-selected='false'] {
    color: $color-grayb-500;
}
body:has(.community) {
    .repl-wrap {
        bottom: initial !important;
    }
}
// background: linear-gradient(90deg, var(--palette_gray_gray_50) 0%,
// var(--palette_gray_gray_50) 25%, var(--palette_gray_gray_150) 50%,
// var(--palette_gray_gray_50) 75%, var(--palette_gray_gray_50) 100%);
// animation: shine 3s linear infinite;
// Skeleton Shine

@keyframes shine {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 200% 0%;
    }
}

.skeleton {
    width: 100%;
    background: linear-gradient(
        90deg,
        #f1fcf6 0%,
        #f1fcf6 25%,
        #fff 50%,
        #f1fcf6 75%,
        #f1fcf6 100%
    );
    background-size: 200% 100%;
    animation: shine 1s linear infinite;
}

@keyframes wave {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

.wave-animation {
    display: inline-block;
    animation: wave 1s ease-in-out;
    animation-fill-mode: forwards;

    @for $i from 1 through 11 {
        &:nth-child(#{$i}) {
            animation-delay: 0.08s * ($i - 1);
        }
    }
}
