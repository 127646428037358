@import url(//fonts.googleapis.com/earlyaccess/notosans.css);

@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.notosanskr * {
  font-family: 'Noto Sans', sans-serif;
}
html {
	font-size:62.5%;
  width: 100%;
  // height: 100vh;
  height: auto;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn,  img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  box-sizing: border-box;
  word-break: keep-all;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
html, body, main{
  width:100%;
  // position: relative;
  box-sizing: border-box;
  max-width: 100%;
}
body {
  font-size:1rem;
  line-height: 1.25;
  -webkit-text-size-adjust:none; /* 뷰표트 변환시 폰트크기 자동확대 방지 */
  display: contents;
}
section{
  position: relative;
}
dl,ul,ol,menu,li {
  list-style: none;
  box-sizing: border-box;
}
blockquote, q {
  quotes: none;
}
a {color:#333;text-decoration:none}
a:active, a:hover {text-decoration:none}
/* input 기본 스타일 초기화 */
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: none;
  //font-size: 0;
  padding: 0;
  margin: 0;
}
input:focus{
  outline:none;
}
button,input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file'] {
  -webkit-appearance:button;
  border-radius:0
}
input[type='text'],
input[type='password'],
input[type='submit'],
input[type='search'] {
  -webkit-appearance:none;
  border-radius:0
}
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance:none
}
input:checked[type='checkbox'] {
  background-color:#666;
  -webkit-appearance:none;
}
/* Select box 스타일 초기화 */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
img{
  max-width: 100%;
}
fieldset,img {border:0 none}
table {width:100%;border-collapse:collapse;border-spacing:0;empty-cells:show;table-layout:fixed;}
th,td{font-weight:500}
td {word-wrap:break-word; word-break:keep-all;}
fieldset {border:0;margin:0;padding:0;}
legend, caption {visibility:hidden;overflow:hidden;width:0;height:0;font-size:0;line-height:0;}
address, caption,  var{font-style:normal;font-weight:normal}
button{background: inherit; border:none; box-shadow:none; border-radius:0; padding:0; overflow:visible; cursor:pointer;}
textarea {
  width: 100%;
  border: none;
  resize: none;
  &:focus{
    outline: 0;
  }
}