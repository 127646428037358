@import "_variables";
@import "mixin";
.info-wrap{
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  .profile-box{
    display: flex;
    flex: auto;
    align-items: center;
    gap: 1.6rem;
    border-bottom: 0.1rem solid #F8F8F8;
    padding: 0.8rem 0 1.6rem 0;
    .profile-img{
      width: 4.2rem;
      height: 4.2rem;
      img{
        width: 4.2rem;
        height: 4.2rem;
      }
    }
    .profile-info {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      // align-items: center;
      flex: auto;
  
      .profile-info-item {
        // display: flex;
        // align-items: center;
        > button {
          &.round-g{
            display: flex;
            align-items: center;
            gap: 0.4rem;
            &::before {
              // content: '\2665\0020';
              content: ' ';
            
            }
            padding: 0.3rem 0.8rem 0.4rem 0.5rem;
            border-radius: 3.2rem;
            color: #39C48D;
            border: 0.1rem solid rgba(64, 223, 159, 0.50);
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          &.round-r{
            display: flex;
            align-items: center;
            gap: 0.4rem;
            &::before {
              // content: '\2665\0020';
              content: ' ';
            
            }
            padding: 0.3rem 0.8rem 0.4rem 0.5rem;
            border-radius: 3.2rem;
            color: #FF3333;
            border: 0.1rem solid rgba(223, 64, 64, 0.5);
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .info{
          display: flex;
          align-items: center;
          gap: 0.4rem;
          .nickname{
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .level{
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .bg-color{
            display: block;
            width: 1.4rem;
            height: 1.4rem;
            background-color: #879AC1;
            border-radius: 0.4rem;
          }
        }
        .util{
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $color-grayb-400;
          display: flex;
          gap: 0.5rem;
          .util-item {
            display: flex;
            gap: 0.1rem;
            p {
              @include caption-noto ($color-grayb-600);
            }
          }
        }
  
      }
  
    }
  }
}
.my-history {
  .info-wrap {
    padding-top: 0.5rem;
  }
}