@import '_variables';
@import 'mixin';
// 팝업 공통
.popup-list {
    max-width: 72rem;
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.3);
    // 여기 fixed 없애면안됨
    .popup-wrap {
        background-color: #fff;
        width: 100%;
        position: absolute;
        bottom: 0;
        border-radius: 2.4rem 2.4rem 0 0;
        overflow: hidden;
        z-index: 99999;
        .popup-title {
            padding: 2.4rem;
            strong {
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .reference {
                padding-top: 0.8rem;
            }
            &.popup-close {
                font-size: 2rem;
                font-weight: 700;
                color: #000;
                display: flex;
                justify-content: space-between;
                border-bottom: 0.1rem solid #f5f5f5;
            }
        }
        .popup-content {
            max-height: 42.5rem;
            // overflow-y: auto;
            .content-tab {
                display: flex;
                .city {
                    width: 12rem;
                    height: 47.4rem;
                    flex: 0 0 12rem;
                    text-align: center;
                    li {
                        button {
                            width: 100%;
                            height: 5.6rem;
                            padding: 0 1rem;
                            background-color: $color-grayb-50;
                            color: $color-grayb-500;
                            font-size: 1.4rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            border-bottom: 0.1rem solid #fff;
                            word-break: break-all;
                            &.active {
                                background-color: #ffffff;
                                color: #000;
                                font-size: 1.6rem;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                            }
                        }
                    }
                }
                .district {
                    width: 100%;
                    height: 47.4rem;
                    li {
                        button {
                            width: 100%;
                            height: 5.6rem;
                            padding: 1.6rem 2.4rem;
                            text-align: left;
                            border-bottom: 0.1rem solid #f5f5f5;
                            font-size: 1.6rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            color: #000;
                            &.check {
                                font-size: 1.6rem;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                color: $color-main-600;
                                position: relative;
                                &:after {
                                    content: '';
                                    background: url('../images/check-active.svg')
                                        no-repeat right 50%;
                                    position: absolute;
                                    right: 2rem;
                                    width: 2.4rem;
                                    height: 2.4rem;
                                }
                            }
                        }
                    }
                }
            }
            .terms-chcek {
                .checkbox {
                    padding: 0 1.6rem;
                    .check-mark {
                        margin-right: 0.8rem;
                    }
                }
                .check-all {
                    padding: 2rem 1.6rem;
                    border-bottom: 0.1rem solid #f5f5f5;
                    label {
                        font-size: 1.6rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
                ul {
                    padding: 2rem 0 4rem;
                    li {
                        & + li {
                            margin-top: 2.5rem;
                        }
                        .checkbox {
                            display: flex;
                            .view-more {
                                margin-left: auto;
                                @include caption-noto($color-grayb-600);
                            }
                        }
                    }
                }
            }
        }
    }

    .popup-wrap2 {
        background-color: #fff;
        width: 90%;
        position: absolute;
        bottom: 4.2rem;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 2.4rem;
        padding: 2.5rem;
        .popup-title {
            padding: 2.4rem;
            .reference {
                padding-top: 0.8rem;
            }
            &.popup-close {
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: #000;
                display: flex;
                justify-content: space-between;
                border-bottom: 0.1rem solid #f5f5f5;
            }
        }
        .popup-content {
            .content-tab {
                display: flex;
                .city {
                    width: 12rem;
                    height: 47.4rem;
                    flex: 0 0 12rem;
                    text-align: center;
                    li {
                        button {
                            width: 100%;
                            height: 5.6rem;
                            padding: 1.6rem 2.4rem;
                            background-color: $color-grayb-50;
                            color: #a4b7af;
                            font-size: 1.4rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            border-bottom: 0.1rem solid #fff;
                            &.active {
                                background-color: #ffffff;
                                color: #000;
                                font-size: 1.6rem;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                            }
                        }
                    }
                }
                .district {
                    width: 100%;
                    height: 47.4rem;
                    li {
                        button {
                            width: 100%;
                            height: 5.6rem;
                            padding: 1.6rem 2.4rem;
                            text-align: left;
                            border-bottom: 0.1rem solid #f5f5f5;
                            font-size: 1.6rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            color: #000;
                            &.check {
                                font-size: 1.6rem;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                color: $color-main-500;
                                position: relative;
                                &:after {
                                    content: '';
                                    background: url('../images/checkbox-on.svg')
                                        no-repeat right 50%;
                                    position: absolute;
                                    right: 2rem;
                                    width: 2.4rem;
                                    height: 2.4rem;
                                }
                            }
                        }
                    }
                }
            }
            .terms-chcek {
                .checkbox {
                    padding: 0 1.6rem;
                    .check-mark {
                        margin-right: 0.8rem;
                    }
                }
                .check-all {
                    padding: 2rem 2.6rem;
                    border-bottom: 0.1rem solid #f5f5f5;
                    label {
                        font-size: 1.6rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
                ul {
                    padding: 2rem 0 4rem;
                    li {
                        & + li {
                            margin-top: 2.5rem;
                        }
                        .checkbox {
                            display: flex;
                            .view-more {
                                margin-left: auto;
                                font-size: 1.2rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                color: #bdbdbd;
                            }
                        }
                    }
                }
            }
        }
        .button-wrap2 {
            padding: 0;
        }
    }

    //추가
    .popup-wrap4 {
        background-color: #fff;
        // width: 31.5rem;
        width: 28rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1.4rem;
        .popup-title {
            padding: 2rem 2.4rem;
            &:has(.popup-title2) {
                padding: 3rem 2rem 1.05rem 2rem;
                border-bottom: 0.1rem solid #f5f5f5;
            }
            strong {
                font-size: 1.8rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            img {
                vertical-align: sub;
                margin-right: 0.4rem;
            }
        }
        .popup-title1 {
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
        }
        .popup-title2 {
            position: relative;
            font-size: 1.8rem;
            font-weight: 800;
            &::before {
                content: '안내사항';
                color: #ff567b;
                position: absolute;
                top: -1.4rem;
                left: 0;
                font-size: 1.2rem;
                font-weight: 400;
            }
        }
        .reference {
            margin-top: 0.4rem;
        }
        .popup-content {
            // border-bottom: 0.1rem solid #F5F5F5;
            // padding: 1.2rem 2.4rem;
            .radio-wrap {
                ul {
                    li {
                        font-size: 1.4rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: #9e9e9e;
                        + li {
                            margin-top: 2.9rem;
                        }
                        label {
                            vertical-align: bottom;
                            font-size: 1.4rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            color: #9e9e9e;
                        }
                        .ico-radio {
                            margin-right: 2rem;
                        }
                    }
                }
            }
            .leave-info {
                p {
                    font-size: 1.4rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: #9e9e9e;
                }
            }
        }
        .button-wrap2 {
            padding: 1.3rem 1.2rem;
            display: flex;
            justify-content: space-between;
            gap: 1.2rem;
            > * {
                padding: 0;
            }
            :first-child {
                text-align: left;
            }
            button {
                display: inline-block;
                // width: 7.6rem;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: right;
                color: #bdbdbd;
                &.point-g {
                    // font-size: 1.4rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    color: #2ebf93;
                }
            }
        }
    }
    &.terms-agree-modal {
        .popup-title {
            padding-left: 2rem;
            padding-top: 3.2rem;
            padding-right: 2rem;
            padding-bottom: 4rem;
        }
        .popup-content {
            .check-all {
                padding-top: 0 !important;
                padding-right: 1.6rem !important;
                padding-bottom: 2rem !important;
                padding-left: 1.6rem !important;
            }
        }
    }
}
.popup-list.popup-bottom-round {
    left: 50%;
    transform: translateX(-50%);
    background-color: none;
}
body:has(.real-home, .for-popup) {
    .adm-center-popup-wrap {
        top: 43%;
        // left: initial;
        // transform: initial;
        // 왜인지는 모르겠지만. 이렇게 해서 메인팝업 가운데로 옴. 그전에는 하단탭을 제외한 영역에서 가운데로 위치함(밑에 여백이 더 길어보였음)
        // -> 추가: 배경클릭시 팝업안사라져서 다시 주석처리함.
        .alert-modal {
            .popup-wrap4 {
                background: #fff;
            }
        }
    }
    .popup-wrap4 {
        background: initial;
        // 왜 initial했지?
        border-radius: 1.6rem;
        .button-wrap2 {
            margin-top: -0.05rem;
            // 배너 컨텐츠와 button-wrap사이의 공백메꾸기

            background: #fff;
            border-radius: 0 0 1.6rem 1.6rem;
            button {
                width: fit-content;
                white-space: nowrap;
            }
        }
        .adm-image {
            border-radius: 1.6rem 1.6rem 0 0;
        }
    }
}

.popup-layer {
    &.popup-community {
        background-color: transparent;
    }
    @media (max-width: 669px) {
        max-width: 72rem;
        margin: 0 auto;
    }

    //버튼

    .button-wrap {
        max-width: 72rem;
        width: 100%;
        position: initial;
        transform: initial;
        padding: 2.4rem 2rem 3.2rem;
        background: #fff;
        > * {
            border: 0;
            display: block;
            width: 100%;
            padding: 1.6rem 1rem;
            text-align: center;
            font-size: 1.6rem;
            font-weight: 700;
            border-radius: 1.6rem;
            &.reference {
                padding: 0 0 1.3rem;
            }
        }
        .default {
            background-color: $color-grayb-50;
            color: $color-grayb-400;
        }
        .normal {
            background-color: #36423d;
        }
        .disabled {
            background-color: $color-grayb-50;
            color: $color-grayb-400;
        }
        .active {
            background-color: $color-main-500;
            color: #fff;
        }
        .location {
            background-color: #36423d;
            color: #fff;
        }
    }

    &.popup-area {
        .text-area {
            display: grid;
            grid-template-columns: 1fr;
            gap: 2rem 0;
            margin: 0 auto;
            padding: 1.6rem 3rem;
            width: calc(100% - 4rem);
            background-color: $color-grayb-50;
            border-radius: 1rem;
            p {
                position: relative;
                font-size: 1.2rem;
                line-height: 1.4;
                &.dot::before {
                    position: absolute;
                    margin-left: -3%;
                    content: '\2022';
                    color: #aab6af;
                }
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    margin-left: 5%;
                }
                strong {
                    vertical-align: initial;
                }
            }

            .link-to-faq {
                font-size: 1.2rem;
                font-weight: 600;
                color: #2ebf93;
            }
        }
    }
}
.popup-notice {
    .popup-head.r-15 {
        padding: 2rem 2rem 1rem 2rem;
    }
    .label-text-guide {
        font-size: 1.2rem;
        color: #ff567b;
    }
}
.modal {
    position: absolute;
    bottom: 0;
    background-color: #fff;
    border: 0.1rem solid #ccc;
    width: 50rem;
    height: 50rem;
}
.close-small {
    width: 1.6rem;
    height: 1.6rem;
}
.popup-diary {
    .popup-title-wrap-diary {
        display: flex;
        align-items: center;
    }
    .popup-wrap {
        .popup-head {
            align-items: center;
            p.text {
                display: inline-block;
                margin-left: 0.8rem;
                font-size: 1.6rem;
            }
        }
        .text-box {
            p:last-child {
                margin-top: 2.4rem;
            }
        }
    }
    &.pet-add-info-modal {
        .text-box {
            p:last-child {
                margin-top: 1.6rem;
            }
        }
    }
}
.popup-setting {
    .popup-wrap {
        &.type-center {
            top: 75%;
        }
        .popup-head {
            display: flex;
            align-items: center;
            strong {
                margin-left: 0.4rem;
                font-size: 1.8rem;
                color: $color-grayb-600;
            }
        }
        .popup-content {
            .text-box {
                p:last-child {
                    margin-top: 2.4rem;
                }
            }
        }
    }
}
.checkbox-list {
    padding-bottom: 1.6rem;
    .title-text {
        font-size: 1.6rem;
        font-weight: 700;
        padding: 1rem 0;
        margin-left: 0.3rem;
    }
}

.year-picker-modal {
    .month-calendar {
        margin: 0;
    }
    .year-move {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3rem 0.8rem;
        color: $color-main-600;
        font-size: 1.8rem;
        font-weight: 600;
        background-color: $white;
    }

    // 이거 삭제해도 될것같은데...일단 둔다
    .box-choice-list,
    .asset-modal.popup-content {
        ul {
            li {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: 5rem 5rem 5rem;
                gap: 0.4rem;
                padding: 0 2rem;
                .month-choice {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    background-color: $color-grayb-50;
                    border-radius: 0.8rem;

                    label {
                        font-size: 1.6rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    input[type='radio'] {
                        display: none;
                    }

                    input[type='radio'] + label {
                        display: inline-block;
                        vertical-align: middle;

                        cursor: pointer;
                        color: #000;
                    }

                    input[type='radio']:checked + label {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        color: #2ebf93;
                        background-color: $color-main-50;
                        border-radius: 0.8rem;
                        font-size: 1.6rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
                .asset-choice-add,
                .group-choice-add {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $color-main-50;
                    border-radius: 0.8rem;
                }
                .calculator-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0.8rem;
                    cursor: pointer;

                    &.number {
                        border: 0.1rem solid #eeeeee;
                        button {
                            font-size: 1.6rem;
                        }
                    }
                    &.calculation-A {
                        background-color: $color-main-50;
                        button {
                            color: $color-main-600;
                            font-weight: 700;
                            font-size: 2.2rem;
                        }
                    }
                    &.calculation-B {
                        background-color: $color-grayb-50;
                        button {
                            color: $black;
                            font-size: 2.2rem;
                        }
                    }
                    &.check {
                        background-color: $color-main-500;
                        button {
                            color: $white;
                            font-weight: bold;
                        }
                    }
                }
                .calculator-number {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $black;
                    border: 0.1rem solid $color-grayb-200;
                }
            }
        }
    }
    ul {
        li {
            .text-bold {
                font-weight: 700;
            }
            .profile-content {
                // margin-top: 0.4rem;
                .flex-align {
                    gap: 0.8rem;
                    margin-top: 0.4rem;
                }
                .animal-info {
                    display: flex;
                    gap: 0.8rem;
                }
            }
        }
    }
}

.account-book-write-popup {
    &.calculator {
        .display {
            width: 100%;
            min-height: 3.8rem;
            background-color: $color-grayb-50;
            border-radius: 0.8rem;
            .value {
                background-color: $color-grayb-50;
                border-radius: 0.8rem;
                padding: 3% 0.8rem;
                color: $color-main-600;
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 1.3;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }
        }
    }
}
.box-choice-list {
    ul {
        li {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 5rem 5rem 5rem;
            gap: 0.4rem;
            padding: 0 2rem;
            .month-choice,
            .asset-choice,
            .group-choice {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                background-color: $color-grayb-50;
                border-radius: 0.8rem;

                label {
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                input[type='radio'] {
                    display: none;
                }

                input[type='radio'] + label {
                    padding: 0 2rem;
                    display: inline-block;
                    vertical-align: middle;

                    cursor: pointer;
                    color: #000;
                }

                input[type='radio']:checked + label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    color: #2ebf93;
                    background-color: $color-main-50;
                    border-radius: 0.8rem;
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
            .asset-choice-add,
            .group-choice-add {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color-main-50;
                border-radius: 0.8rem;
            }
            .calculator-item {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0.8rem;
                cursor: pointer;

                &.number {
                    border: 0.1rem solid #eeeeee;
                    button {
                        font-size: 1.6rem;
                        color: #000;
                    }
                }
                &.calculation-A {
                    background-color: $color-main-50;
                    button {
                        @include head-h3($color-main-600);
                    }
                }
                &.calculation-B {
                    background-color: $color-grayb-50;
                    button {
                        color: $black;
                        font-size: 2.2rem;
                    }
                    &:has(img) {
                        button {
                            width: 2.4rem;
                            height: 2.4rem;
                        }
                    }
                }
                &.check {
                    background-color: $color-main-500;
                    border: none !important;
                    button {
                        color: $white;
                        font-weight: 400;
                        font-size: 1.6rem;
                        border: none !important;
                    }
                    .cmp-base-button {
                        height: initial;
                        background-color: none;
                    }
                }
            }
            .calculator-number {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $black;
                border: 0.1rem solid $color-grayb-200;
            }
        }
    }
    &.group-choice-wrap {
        ul {
            li {
                padding-top: 1.6rem;
                grid-template-rows: 5rem 5rem;
            }
        }
    }
    &.calculator-wrap {
        ul {
            li {
                grid-template-rows: 5rem 5rem 5rem 5rem 5rem;
            }
        }
    }
}

.toast-message-common {
    position: relative;
    &::before {
        @include toast-message;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 999999999999;
    }
}
// .social:has(.toast-message) {
//   position:relative;
//   &::before{
//     @include toast-message;
//     position: absolute;
//     left: 50%;
//     top:50%;
//     transform: translate(-50%, -3%);
//     z-index: 99;
//   }
// }
// .popup-bottom {
//   .choice-modal {
//     .popup-content:has(.toast-message) {
//       position:relative;
//         &::before{
//           @include toast-message;
//           position: absolute;
//           left: 50%;
//           top: -12%;
//           transform: translate(-50%, -50%);
//         }
//     }
//   }

// }
.note-share-give:has(.toast-message) {
    &::before {
        @include toast-message;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
.adm-popup-body-position-bottom {
    &:has(.toast-message) {
        overflow: initial;
        .category {
            border-radius: 2.4rem 2.4rem 0 0;
            overflow: hidden;
        }
    }
}
.popup-notification-time {
    bottom: 10.8rem;
}
@media (min-width: 720px) {
    html {
        background-color: #eee;
    }
    /* pc 에서 ant design Popup 가운데 정렬 */
    .adm-popup-body {
        position: fixed;
        background-color: var(--adm-color-background);
        z-index: calc(var(--z-index) + 10);
        transform: translate(-50%, 0) !important;
    }
    /* pc 에서 ant design Popup 가운데 정렬 */
    .adm-popup-body-position-top {
        width: 100%;
        top: 50%;
        left: 50%;
        max-width: 72rem;
    }
}
.adm-popup-body-position-bottom {
    border-radius: 2.4rem 2.4rem 0 0 !important;
    box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
    overflow: hidden;
    &:has(.area-picker-modal) {
        overflow: initial;
    }
    @media (min-width: 720px) {
        width: 100%;
        // top: 50%;
        left: 50%;
        max-width: 72rem;
    }
}

.adm-swiper-indicator {
    display: none;
}
/* 모달 등 상/하 border-raius 설정*/
.up-radius24 {
    border-radius: 2.4rem 2.4rem 0 0;
}
.down-radius24 {
    border-radius: 0 0 2.4rem 2.4rem;
}

// <ButtomPopUp />
.popup-head {
    border-bottom: 0.1rem solid $popup-content-list-line;
    strong {
        .popup-bottom {
            font-size: 2rem;
            color: #000;
        }
        .popup-bottom-round & {
            font-size: 1.8rem;
            color: $color-grayb-600;
            font-weight: 700;
        }
    }
    &.popup-close {
        height: 7.6rem;
        padding-right: 2rem;
        padding-left: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 2rem;
        font-weight: 700;
        color: #000;
        button {
            width: 2.4rem;
            height: 2.4rem;
        }
    }
    .pet-add-info-modal & {
        height: auto;
        padding-top: 2.4rem;
        padding-bottom: 1.6rem;
        &.popup-close {
            button {
                width: 1.6rem;
                height: 1.6rem;
            }
        }
    }
    .subject-modal & {
        padding-top: 1.45rem;
        padding-right: 2rem;
        padding-bottom: 2.25rem;
        padding-left: 2rem;
    }
    .animal-modal & {
        padding: 2rem;
    }
    .popup-bottom-round & {
        padding-top: 2.4rem;
        padding-left: 2rem;
        padding-bottom: 2.4rem;
    }
    .calculator & {
        gap: 1.1rem;
        margin-top: 2rem;
        &.popup-close {
            height: auto;
        }
        button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .popup-bottom-round & {
        img {
            vertical-align: sub;
            margin-right: 0.4rem;
        }
        &.level {
            p {
                display: flex;
                align-items: center;
                strong {
                    color: $color-grayb-600;
                }
            }
        }
    }
}

.popup-bottom {
    .subject-modal,
    .my-pet-modal,
    .calculator {
        // my-pet-modal은 클릭시 border없어지는 효과로 해당 모달에서 border줌
        .popup-head {
            border-bottom: none;
            &:has(.popup-title-sub) {
                & {
                    border-bottom: none;
                }
            }
        }
    }
    .subject-modal,
    .animal-modal {
        // '전체 카테고리' '글쓰기' , '전체 선호동물'
        .popup-head {
            &:has(.popup-title-sub) {
                & {
                    display: grid;
                    grid-template-columns: 1fr 2.4rem;
                    grid-template-rows: min-content min-content;
                    gap: 0.3rem;
                    height: auto;
                    .popup-title-sub {
                        font-size: 1.2rem;
                        font-weight: 500;
                        color: $color-grayb-600;
                    }
                    button {
                        grid-column: 2 / 3;
                        grid-row: -3 / -1;
                    }
                }
            }
        }
        &:has(.popup-title-sub) {
            .popup-content {
                .category {
                    li:first-of-type {
                        height: 7.2rem;
                        border-bottom: 0.8rem solid #fafafa;
                        button {
                            font-size: 1.8rem;
                        }
                    }
                }
            }
        }
    }

    .popup-content {
        .category {
            width: 100%;
            li {
                padding: 0 2rem;
                height: 5.6rem;
                + li {
                    border-top: 0.1rem solid $popup-content-list-line;
                }
                &.help {
                    height: auto;
                    margin: 0.8rem 0;
                    padding: 0;
                    border-top: none;
                    + li {
                        border-top: none;
                    }
                    a {
                        padding: 0 1.6rem;
                        width: 100%;
                        height: 2.8rem;
                        display: flex;
                        align-items: center;
                        background: $color-grayb-50;
                        color: $color-grayb-500;
                        font-size: 1.2rem;
                        font-weight: 700;
                    }
                }
                button {
                    width: 100%;
                    height: 100%;
                    font-size: 1.6rem;
                    font-weight: 400;
                    color: $black;
                }
            }
        }
        .radio-wrap {
            border-top: 0.1rem solid #f5f5f5;
            padding: 1.5rem 0;
            ul {
                li {
                    font-size: 1.4rem;
                    font-weight: 400;
                    color: $color-grayb-500;
                    + li {
                        margin-top: 1.4rem;
                    }
                    label {
                        vertical-align: bottom;
                    }
                    .ico-radio {
                        margin-right: 1.6rem;
                    }
                }
            }
        }
        .bell-box {
            display: flex;
            justify-content: center;
            gap: 1.7rem;
            > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 1.8rem;
                padding: 0.8rem;
                p {
                    padding: 1.15rem 1.7rem;
                    font-size: 1.8rem;
                    font-weight: 700;
                    border-radius: 2rem;
                    background-color: $color-grayb-50;
                    color: $color-grayb-600;
                }
            }
        }
    }

    &:has(.subject-modal, .area-filter-modal) {
        .category {
            li {
                button {
                    position: relative;
                    display: flex;
                    align-items: center;
                    text-align: left;
                    &:after {
                        content: '';
                        background: url('../images/check-inactive.svg')
                            no-repeat right 50%;
                        position: absolute;
                        right: 0;
                        width: 2.4rem;
                        height: 2.4rem;
                    }
                    &.check {
                        font-size: 1.6rem;
                        font-weight: 700;
                        color: $color-main-600;
                        position: relative;
                        &:after {
                            content: '';
                            background: url('../images/check-active.svg')
                                no-repeat right 50%;
                            position: absolute;
                            right: 0;
                            width: 2.4rem;
                            height: 2.4rem;
                        }
                    }
                }
            }
        }
    }
    &:has(.area-filter-modal) {
        .category {
            li {
                height: 7.2rem;
            }
        }
        .text-box {
            display: flex;
            flex-direction: column;
            gap: 1.8rem;
            margin: 0.3rem auto 0 auto;
            padding: 1.6rem 3rem;
            width: calc(100% - 4rem);
            border-radius: 10px;
            background: $color-grayb-50;
            p {
                font-size: 1.2rem;
                font-weight: 400;
                &.dot {
                    position: relative;
                    padding-left: 1.8rem;
                }
                &.dot:before {
                    position: absolute;
                    display: inline-block;
                    top: 0.7rem;
                    left: 0;
                    margin-left: 0.7rem;
                    // transform: translate(0, -50%);
                    vertical-align: middle;
                    content: '';
                    width: 0.4rem;
                    height: 0.4rem;
                    background-color: #000;
                    border-radius: 70%;
                }
            }
            .point-g {
                font-size: 1.2rem;
                font-weight: 700;
            }
        }
    }
    &:has(.my-pet-modal) {
        .popup-content {
            ul.animal-list {
                // 프로필&스크롤 들어가느라 변경되는게 많아서 가독성 위해 .category로 안함
                height: auto;
                max-height: 36rem;
                overflow-y: auto;
                // li 4칸 이상시 스크롤생김
                li {
                    display: flex;
                    align-items: center;
                    gap: 1.2rem;
                    height: 9rem;
                    border-top: 0.1rem solid $color-grayb-100;
                    padding: 0 2rem;
                    &.checked {
                        border: none;
                        background-color: $color-main-50;
                        + li {
                            border: none;
                        }
                    }
                    .all-choice {
                        display: flex;
                        align-items: center;
                        gap: 1.2rem;
                    }
                }
                .text-bold {
                    font-weight: 700;
                }
                .profile-content {
                    // margin-top: 0.4rem;
                    .flex-align {
                        gap: 0.8rem;
                        margin-top: 0.4rem;
                    }
                    .animal-info {
                        display: flex;
                        gap: 0.8rem;
                    }
                }
            }
        }
    }
    &:has(.choice-modal) {
        .popup-content {
            .category {
                &:has(.checked) {
                    li:nth-of-type(1) {
                        border-radius: 2.4rem 2.4rem 0 0;
                    }
                }
                li {
                    height: 7.2rem;
                    &.checked {
                        border: none;
                        background-color: $color-main-50;
                        + li {
                            border: none;
                        }
                    }
                    button {
                        font-size: 1.8rem;
                        &.point-r {
                            color: $color-danger-500;
                        }
                        &.point-g {
                            color: $color-main-600;
                        }
                        // 우선순위로 common에 있는게 button color에 먹혀서 또 줘야함
                    }
                }
            }
        }
    }
}

.popup-content {
    .popup-bottom-round & {
        padding-top: 2.4rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        padding-bottom: 2.4rem;
        .text-box {
            font-size: 1.4rem;
            color: #000;
            font-weight: 400;
        }
    }
}
.access-permission-popup {
    .popup-content {
        .text-box {
            &.gray {
                font-size: 1.2rem;
                font-weight: 400;
                color: $color-grayb-600;
            }
        }
    }
}
.popup-wrap {
    .popup-bottom-round & {
        margin-bottom: 2.4rem;
        margin-left: 2rem;
        margin-right: 2rem;
        width: calc(100% - 4rem);
        border-radius: 2.4rem;
    }
}
.popup-button-wrap {
    padding-top: 2.4rem;
    padding-right: 2rem;
    padding-left: 2rem;

    .time-modal & {
        padding-top: 1.6rem;
    }
    .popup-bottom-round & {
        padding-top: 2.4rem;
        padding-right: 2.4rem;
        padding-bottom: 2.4rem;
        padding-left: 2.4rem;
    }
    button {
        width: 100%;
        height: 5.4rem;
        background-color: $color-main-500;
        border: 1px solid $color-main-500;
        border-radius: 1.6rem;
        color: #fff;
        font-size: 1.6rem;
        font-weight: bold;
        &.disabled {
            background-color: $color-grayb-50;
            border: 1px solid $color-grayb-50;
            color: $color-grayb-400;
        }
        &.active {
            background-color: $color-main-500;
            color: #fff;
        }
    }
}
@media (min-width: 720px) {
    .popup-button-wrap {
        padding-bottom: 2rem;
    }
}
.popup-content {
    font-size: 1.4rem;
    line-height: 1.4;
    .pet-add-info-modal & {
        font-weight: 500;
    }
    .calculator & {
        .divider-01 {
            margin: 1.7rem 0 1.6rem 0;
            border-color: #f5f5f5;
        }
    }
    .asset-modal &,
    .wallet-category-modal & {
        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 0.4rem;
            li {
                > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // width: 8rem;
                    padding: 0 2rem;
                    height: 5rem;
                    border-radius: 0.8rem;
                    background-color: $color-grayb-50;
                    &.item-add {
                        width: 8rem;
                    }
                }
                label {
                    font-size: 1.6rem;
                }
                input[type='radio'] {
                    display: none;
                }
                input[type='radio'] + label {
                    display: inline-block;
                    vertical-align: middle;
                    cursor: pointer;
                    color: #000;
                    font-weight: 400;
                }

                input[type='radio']:checked + label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    color: #2ebf93;
                    background-color: $color-main-50;
                    border-radius: 0.8rem;
                    font-size: 1.6rem;
                    font-weight: 700;
                }
            }
        }
        .item-wrap {
            display: flex;
            flex-wrap: wrap;
            gap: 0.4rem;
        }
        .item {
            background-color: $color-grayb-50;
        }
        .item-add {
            background-color: $color-main-50;
        }
    }
    .time-modal & {
        strong {
            display: block;
            font-size: 1.8rem;
        }
    }

    //community
    .popup-content-list {
        li {
            text-align: center;
            border-bottom: 0.1rem solid $popup-content-list-line;
            button {
                padding: 2.4rem 0;
                font-size: 1.8rem;
                font-family: 'Noto Sans';
            }
            &:nth-of-type(1) {
                button {
                    padding-top: 0;
                }
            }
            &:last-child {
                border: none;
            }
        }
    }
    .text-box {
        p {
            span {
                vertical-align: initial;
            }
        }
        &.gray {
            color: #c7c7c7;
        }
        .text-title {
            margin-top: 2.4rem;
        }
        .text-content {
            padding-left: 1.1rem;
            line-height: 1.4;
        }
    }
}
.account-book-write-popup,
.wallet-category-modal {
    .popup-content ul li {
        .item {
            padding: 0;
            > label {
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }
        .item-add {
            > label {
                font-size: 0;
                > button {
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }
        }
    }
}
.popup-footer {
    padding: 1.3rem 0 0;
    display: flex;
    justify-content: center;
    gap: 1.2rem;
    .pet-add-info-modal & {
        padding: 0;
    }
    &.r-15 {
        border-radius: 0 0 1.5rem 1.5rem;
    }
    &.r-16 {
        border-radius: 0 0 1.6rem 1.6rem;
    }
    &.r-24 {
        border-radius: 0 0 2.4rem 2.4rem;
    }
    button {
        display: block;
        margin: 2.4rem 0 3.2rem 0;
        .pet-add-info-modal & {
            margin: 2.4rem 0;
        }
        padding: 1.6rem 0;
        width: calc(100% - 4rem);
        border-radius: 1.6rem;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: $color-main-500;
        color: #fff;
    }
}

// <CenterPopup />
.popup-head {
    border-bottom: 0.1rem solid $popup-content-list-line;
    .confirm-modal & {
        padding: 2.4rem 2.4rem 1.6rem 2.4rem;
        border: none;
    }
}
.popup-wrap-center:has(.complain-modal) {
    .popup-head {
        display: flex;
        align-items: center;
        height: 6.4rem;
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 2.4rem;
        border-bottom: 0.1rem solid $popup-content-list-line;
    }
}
.popup-wrap3,
.popup-wrap-center,
.confirm-modal {
    .popup-title1 {
        font-size: 1.8rem;
        font-weight: 700;
    }
    .popup-titles {
        padding: 2rem 2.4rem;
        border-bottom: 0.1rem solid #f5f5f5;
        strong {
            font-size: 1.8rem;
            font-weight: 700;
        }
        img {
            vertical-align: sub;
            margin-right: 0.4rem;
        }
    }

    .reference {
        margin-top: 0.4rem;
    }
}
.button-wrap2 {
    padding: 1.4rem 2rem 2rem;
    display: flex;
    justify-content: flex-end;
    gap: 1.2rem;
    // border-top: 1px solid #E2E4E4;
    > * {
        padding: 0;
    }
    button {
        display: inline-block;
        width: 7.6rem;
        color: #bdbdbd;
        font-size: 1.4rem;
        font-weight: 400;
        text-align: right;
        &.point-r {
            color: #ff567b;
            font-weight: 400;
        }
    }
    .confirm-modal & {
        gap: 3.2rem;
        button {
            width: initial;
            &:first-of-type {
                color: #ff567b;
            }
            &:last-of-type {
                color: #2ebf93;
                font-weight: 700;
            }
        }
    }
    .image-modal & {
        button {
            width: initial;
        }
    }
}
.confirm-modal:has(.complain-modal) {
    .button-wrap2 {
        button {
            &:first-of-type {
                color: $color-grayb-400;
            }
            &:last-of-type {
                color: #ff567b;
                font-weight: 700;
            }
        }
    }
}
.popup-content {
    .radio-wrap {
        ul {
            li {
                font-size: 1.4rem;
                font-weight: 400;
                color: $color-grayb-500;
                + li {
                    // margin-top: 2.9rem;
                }
                label {
                    vertical-align: bottom;
                    font-size: 1.4rem;
                    font-weight: 400;
                    color: $color-grayb-600;
                }
                .ico-radio {
                    margin-right: 2rem;
                }
            }
        }
    }
    .complain-modal {
        ul {
            border-bottom: 1px solid #e2e4e4;
            li {
                display: flex;
                align-items: center;
                height: 4.8rem;
                padding-left: 2.4rem;
                label {
                    display: grid;
                    grid-template-columns: min-content 1fr;
                    p {
                        line-height: 1.7;
                    }
                }
            }
        }
    }
    .leave-info {
        p {
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: $color-grayb-500;
        }
    }
}
.popup-center-footer {
    .alert-modal & {
        padding: 1.3rem 1.2rem !important;
        display: flex;
        justify-content: center;
        gap: 1.2rem;
        border-top: 1px solid $color-grayb-100;
        > * {
            padding: 0;
        }
        :first-child {
            text-align: left;
        }
        button {
            display: inline-block;
            font-size: 1.4rem;
            font-weight: 700;
            text-align: center;
            color: $color-main-600;
            &.point-g {
                font-weight: 700;
                color: #2ebf93;
            }
            &.point-r {
                color: #ff567b;
                font-weight: 700;
            }
        }
    }
}
.popup-wrap-center {
    background-color: #fff;
    border-radius: 1.6rem;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}
.alert-modal:has(.popup-title2) {
    .popup-content {
        padding: 1.6rem;
    }
    .popup-center-footer {
        padding: 2.4rem;
        border: none;
        button {
            display: block;
            padding: 1.6rem 0;
            width: 100%;
            background-color: #40df9f;
            color: #fff;
            text-align: center;
            font-weight: 700;
            border-radius: 1.6rem;
        }
    }
}
.alert-modal {
    .popup-wrap4 {
        .popup-content {
            > div > p {
                margin-top: -2rem;
                padding: 0 2.4rem 2rem 2.4rem;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: normal;
                color: #757575;
            }
        }
    }
}
.level-info {
    font-size: 1.4rem;
    font-weight: 500;
    p:first-child {
        margin-bottom: 2.4rem;
    }
    .grade-list {
        li {
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }
    }
    .what-grade {
        margin-top: 2.4rem;
    }
}

// S: css-component.css에 있던 것 (천수석님 작업)

/** bottom modal **/
.cmp-bottom-modal {
    padding: 0 2rem;
    border-radius: 2.4rem 2.4rem 0 0;
}
.cmp-bottom-modal-header {
    display: flex;
    height: 7.2rem;
    align-items: center;
}
.cmp-bottom-modal-header-left {
    flex: 1;
}
.cmp-bottom-modal-header-right {
    /* display: flex; */
    /* justify-content: flex-end; */
}
.cmp-bottom-modal-body {
    margin-top: 0.2rem;
    margin-bottom: 2.4rem;
    height: 18.2rem;
}
.cmp-bottom-modal-footer {
    margin-top: 1.6rem;
    height: 7.8rem;
}
/** bottom popup **/
.cmp-bottom-popup {
    padding: 0 2rem;
    border-radius: 2.4rem 2.4rem 0 0;
    background-color: var(--color-grayb-100);
}
.cmp-bottom-popup-header {
    display: flex;
    height: 7.2rem;
    align-items: center;
}
.cmp-bottom-popup-header-left {
    flex: 1;
    color: var(--color-main-600);
}
.cmp-bottom-popup-header-right {
    /* 클릭 영역 확대 */
    padding: 0.7rem;
    width: 2.8rem;
    height: 2.8rem;
    /* background-color: red; */
    /* color: var(--color-main-600); */
    /* display: flex; */
    /* justify-content: flex-end; */
}
.cmp-bottom-popup-body {
    /* margin-top: 0.2rem;
  margin-bottom: 2.4rem;
  height: 18.2rem; */
}
/* button */
.cmp-base-button {
    width: 100%;
    height: 5.4rem;
    background-color: var(--color-main-500);
    border-radius: 1.6rem;
    font-size: 1.6rem !important;
    font-weight: 700;
}
/* selectbutton 추가*/
button.cmp-base-select-button {
    height: 2.8rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: normal;
    border-radius: 3.7rem;
    padding: 0.45rem 0.5rem 0.45rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.6rem;
    border: 0.1rem solid $color-grayb-300;
    color: $color-grayb-500;
    min-width: 10rem;
}
button.cmp-base-select-button.active {
    border: 0.1rem solid #2ebf93;
    color: #2ebf93;
}
button.cmp-base-select-button img {
    flex: 0 0 2.4rem;
    max-width: unset;
    margin-left: auto;
}
.cmp-base-button.disabled {
    background-color: var($color-grayb-50);
    color: $color-grayb-400;
    font-size: 1.6rem;
}
.cmp-base-button-label {
    color: #ffffff;
    font-weight: 700;
    // <BaseButton/> text color. 주석처리 하면안됨!
}
.animal-modal:has(.cmp-base-button-label) {
    .cmp-base-button-label {
        color: $color-grayb-400;
        font-weight: 700;
    }
}

/* image calendar */
.cmp-image-calendar-week {
    padding-bottom: 0.8rem;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    height: auto;
    /* border: 0.1rem solid black; */
}
.cmp-image-calendar-day-wrap {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cmp-image-calendar-day-wrap::after {
    display: block;
    content: '';
    padding-bottom: 100%;
}
.cmp-image-calendar-day-label {
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-grayb-500);
    font-size: 1.2rem;
}

.cmp-image-calendar-date-wrap {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    width: 100%;
    background-color: #ffffff; /* skyblue; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.1rem solid $color-grayb-50;
    &:nth-child(-n + 7) {
        border-top: 0.1rem solid $color-grayb-50;
    }
}
.cmp-image-calendar-date-wrap::after {
    display: block;
    content: '';
    padding-bottom: 100%;
}
.cmp-image-calendar-date-label {
    position: relative;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 1rem; /* image */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 1.6rem;
    font-weight: 500;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url("https://via.placeholder.com/700"); */
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-color: rgba(0, 0, 0, 0.5);
    //   z-index: 1;
    // }
    // 이미지 오버레이. 숫자까지 덮음. 천수석님 하신거 찾기
}

.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
}
.cmp-image-calendar-date-label:has(.image-calendar) {
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
    }
    .calendar-date {
        font-weight: 700;
    }
}
.image-calendar {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.calendar-date {
    z-index: 999;
    font-size: 1.6rem;
}

/* simple calendar */
.cmp-simple-calendar-week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    &.day {
        grid-template-rows: repeat(1, 2.4rem);
    }
    &.date {
        grid-template-rows: repeat(1, 4.8rem);
    }
    width: 100%;
    height: auto;
    /* border: 0.1rem solid black; */
}
.cmp-simple-calendar-day-wrap {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cmp-simple-calendar-day-wrap::after {
    display: block;
    content: '';
    padding-bottom: 100%;
}
.cmp-simple-calendar-day-label {
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-grayb-500);
    font-size: 1.2rem;
}
.cmp-image-calendar-day,
.cmp-simple-calendar-day-label {
    //요일
    font-size: 1.4rem;
    font-weight: 400;
    //가계부 요일이랑 weight다름.
    color: $color-grayb-500;
}
.cmp-simple-calendar-date-wrap {
    width: 100%;

    background-color: #ffffff; /* skyblue; */
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 0.1rem solid #ffffff;
    // @media screen and (width > 470px) {
    //   height: 5rem;
    //   가계부 달력모달 pc버전 일자 줄이기
    // }
}
.cmp-simple-calendar-date-wrap::after {
    display: block;
    content: '';
    padding-bottom: 100%;
}
.cmp-simple-calendar-date-label {
    width: 3.6rem;
    height: 3.6rem;
    object-fit: cover;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text;
    font-size: 1.2rem;
    background-position: center;
    /* background-image: url("https://via.placeholder.com/700"); */
}
.cmp-diary-calendar-date-wrap {
    width: 100%;
    background-color: #ffffff; /* skyblue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 0.1rem solid $color-grayb-100;
    gap: 0.4rem;
    &:nth-child(-n + 7) {
        border: none;
    }
    + div.cmp-diary-calendar-date-wrap {
    }
}
.cmp-diary-calendar-date-wrap::after {
    // display: block;
    // content: "";
    // padding-bottom: 100%;
}
.cmp-diary-calendar-date-label {
    width: 2rem;
    height: 2rem;
    object-fit: cover;
    border-radius: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    background-position: center;
    /* background-image: url("https://via.placeholder.com/700"); */
}
.cmp-diary-calendar-money-label {
    width: 100%;
    height: 1.4rem;
    text-align: center;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 700;
    color: rgba(46, 191, 147, 1);
}

/* line calendar */
.cmp-line-calender-day-box {
    margin: 0;
    margin-top: -0.1rem;
    padding: 0.4rem 0 0.4rem 0;
    border-radius: 0 0 1.6rem 1.6rem;
    background: #fff;
    box-shadow: 0 0.5rem 0.8rem 0 rgba(99, 99, 99, 0.1);
}
.cmp-line-calendar-day-wrap {
    max-width: 72rem;
    overflow-y: hidden;
    overflow-x: hidden;
}
.cmp-line-calendar-day-label {
    display: table;
    table-layout: fixed;
    width: 100%;
    overflow-x: hidden;
    color: var(--color-grayb-500);
}
.cmp-line-calendar-day-value {
    display: table-cell;
    width: calc(100vw / 7);
    height: 2.4rem;
    max-width: 10.2rem; /* (720px / 7) */
    text-align: center;
    vertical-align: middle;
    font-size: 1.2rem;
    /* background-color: yellow; */
}
.cmp-line-calendar-date-wrap {
    max-width: 74rem;
    overflow-y: hidden;
    overflow-x: hidden;
}
.cmp-line-calendar-date-wrap::-webkit-scrollbar {
    height: 0.3rem;
    background: none;
}
.cmp-line-calendar-date-wrap::-webkit-scrollbar-thumb {
    background-color: rgba(238, 238, 238, 1);
}
.cmp-line-calendar-date-wrap::-webkit-scrollbar-track {
    background-color: #ffffff;
}

.cmp-line-calendar-date-label {
    display: table;
    table-layout: fixed;
    width: 100%;
    overflow-x: scroll;
    gap: 2rem;
}
.cmp-line-calendar-date-value {
    display: table-cell;
    width: calc(100vw / 7);
    height: 4.3rem;
    max-width: 10.2rem; /* (720px / 7) */
    /* padding-bottom: 12px; */
    padding: 1.2rem 1rem;

    text-align: center;
    vertical-align: middle;
    /* background-color: gold; */
    position: relative;
}
.cmp-line-calendar-date-value-circle {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cmp-line-calendar-date-value-circle-today {
    color: var(--color-today-date);
    background-color: var(--bgcolor-today-date);
}
.cmp-line-calendar-date-value-circle-selected {
    background-color: var(--color-main-500);
    color: #fff;
}

/* Tab  */
.customHeader {
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid var(--adm-color-border);

    .tabs {
        flex: 1;
        :global(.adm-tabs-header) {
            border-bottom-width: 0;
        }
    }
    .moreIcon {
        font-size: var(--adm-font-size-9);
        margin: 0 1.2rem;
        transform: translateY(-0.2rem);
    }
}

.handle-box-wrap {
    height: 2rem;
    /* background: #ffffff; */
    background: rgba(0, 0, 0, 0.55);
    /* masking color */
}
.handle-box {
    height: 2rem;
    border: 0.1rem solid #ffffff;
    border-radius: 0 0 1.6rem 1.6rem;
    position: relative;
    padding: 1.2rem 0 0.4rem 0;
    background: #ffffff;
    /* background: red; */
}

.handle {
    width: 4rem;
    height: 0.3rem;
    border-radius: 0.2rem;
    background-color: #eeeeee;
    margin: auto;
}

// E: css-component.css에 있던 것 (천수석님 작업)
.adm-button {
    border: none;
}
.adm-button-disabled {
    opacity: initial !important;
}
.cmp-simple-calendar-date-label {
    font-weight: 400;
    font-size: 1.4rem;
}
.adm-center-popup-body:has(.confirm-modal) {
    border-radius: 1.6rem;
}
.adm-popup-body-position-center:has(.access-permission-popup) {
    border-radius: 1.5rem;
}
.adm-popup-body-position-center:has(.pet-add-info-modal) {
    border-radius: 2.4rem;
}
.adm-mask {
    background: rgba(0, 0, 0, 0.55);
    // year-picker-modal에 adm씌워도 dim처리 안되길래 추가함
}
.area-picker-modal {
    margin-bottom: 11rem;
    .popup-content {
        max-height: initial !important;
    }
    .button-wrap {
        box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.2);
    }
}
@media (max-width: 719px) {
    .area-picker-modal {
        margin-bottom: 7.2rem;
    }
}
.indicator-area {
    margin-bottom: 3.4rem;
}
.adm-center-popup-wrap {
    top: 42%;
    &:has(.complain-modal) {
        top: 32%;
    }
    &:has(.image-modal) {
        top: 48%;
    }
    &:has(.alert-modal) {
        top: 49%;
    }
}

body:has(.terms-agree-modal) {
    .layout-scroll-body {
        height: 100%;
    }
}
.month-stat-wrap {
    // 가계부-달력.
    .cmp-simple-calendar-week {
        &.pd-20 {
            // wallet.scss에서는 우선순위 때문인지 important해도 적용안됨
            padding-left: 0;
            padding-right: 0;
        }
        &:nth-of-type(1) {
            grid-template-rows: 4.3rem;
            margin-bottom: 1.2rem;
        }
        &:nth-of-type(2) {
            .cmp-diary-calendar-date-wrap {
                padding-bottom: 2.4rem;
            }
        }
    }
}

.adm-swiper.adm-swiper-horizontal {
    margin-top: 1.2rem;
    .month-stat-wrap & {
        margin-top: 2rem;
    }
    .real-home & {
        margin-top: 0;
    }
}
.popup-budget-setting.text-box {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}
.popup-faq-txt {
    color: $color-main-600;
    font-weight: 700;
    font-size: 1.4rem;
}
