@import '_variables';

.diary {
    width: 100%;
    height: 100%;
    &.diary3 {
        padding: 0;
    }
    .diary-detail-none {
        .diary-detail {
            display: none;
        }
    }
    .more {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        width: 100%;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        padding: 1rem;
        border-radius: 0.6rem;
        border: 0.1rem solid #9e9e9e;
    }
    .diary-detail {
        height: calc(100% - 7.4rem);
        // padding: 10.5rem 2rem 0 2rem;
        .diary-view {
            margin-top: 7.4rem;
            height: 100%;
        }
        &:has(.ad-area.h160) {
            .diary-view {
                margin-top: 5.5rem;
            }
        }
    }

    .diary-reply-con {
        padding: 1.8rem 2rem;
        > li {
            display: flex;
            gap: 0.8rem;
            align-items: flex-start;
            + li {
                margin-top: 1rem;
            }

            .content-box {
                width: 100%;
                padding: 1.6rem;
                border-radius: 1.6rem;
                .title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .title-xs {
                        width: 100%;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        word-break: break-all;
                        line-height: 1.4;
                    }
                    button {
                        display: flex;
                        align-items: center;
                    }
                }
                .content {
                    .text {
                        margin-top: 12px;
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 1;
                        margin-bottom: 1rem;
                        word-break: break-all;
                    }
                    .img-box {
                        width: 100%;
                        height: 16rem;
                        margin-bottom: 1rem;
                        border-radius: 1.6rem;
                        overflow: hidden;
                        text-align: center;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    @media (min-width: 500px) {
                        .img-box {
                            height: 30rem;
                        }
                    }
                }
                .tag {
                    margin-bottom: 1.6rem;
                    ul {
                        display: flex;
                        gap: 0.4em;
                        li {
                            a {
                                font-size: 1rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: -0.06rem;
                                color: #2ebf93;
                            }
                        }
                    }
                }
                .info {
                    span {
                        font-size: 1.2rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        color: #bdbdbd;
                        text-align: right;
                    }
                    img {
                        vertical-align: bottom;
                    }
                    &.flex-align {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
    &.diary-reply {
        .diary-reply-con {
            display: block;
            padding-top: 15rem;
        }
    }
    &.paid-user {
        .diary-reply-con {
            display: block;
        }
    }

    .diary-reply-con {
        li {
            .content-box {
                background-color: #f1fcf6;
                border-radius: 1.6rem;
                &:has(.img-box) {
                    background-color: #fafafa;
                }
            }
        }
    }

    .membership-wrap {
        width: 100%;
        padding-top: 2.4rem;
        .title-s {
            margin-bottom: 2.4rem;
        }
        .round-box {
            margin-bottom: 2.4rem;
            .item {
                h4 {
                    font-size: 1.4rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: #616161;
                    margin-bottom: 1.2rem;
                }
                p {
                    font-size: 1.4rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    color: #000;
                    &.point-g {
                        color: #2ebf93;
                    }
                }
                + .item {
                    margin-top: 2.4rem;
                }
            }
        }
        .link-box {
            width: 100%;
            text-align: center;
            padding-bottom: 3.6rem;
            a {
                display: block;
            }
            .member-link {
                width: 100%;
                height: 4.8rem;
                padding: 1.3rem;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border-radius: 1.6rem;
                color: #2ebf93;
                background-color: #f1fcf6;
                margin-bottom: 1.2rem;
            }

            .terminate {
                text-align: right;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-decoration-line: underline;
                color: #bdbdbd;
            }
        }
        .premium-box {
            padding-top: 5.6rem;
            h2 {
                text-align: center;
                margin-bottom: 3.7rem;
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .table-box {
                width: 100%;
                ul {
                    border-radius: 2.4rem 2.4rem 0 0;
                    overflow: hidden;
                    width: 100%;
                    border: 0.1rem solid #f5f5f5;
                    li {
                        text-align: center;
                        height: 5.6rem;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 2.4rem;
                        + li {
                            border-top: 0.1rem solid #f5f5f5;
                        }
                        &.point-line {
                            background-color: $color-grayb-50;
                        }
                        div {
                            width: calc(100% / 3);
                            &.ft {
                                text-align: left;
                                font-size: 1.4rem;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                &.ft-1 {
                                    text-align: left;
                                    padding-left: 1rem;
                                }
                            }
                            &.free {
                                font-size: 1.4rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 1.3;
                                padding: 0.4rem 1.4rem;
                                width: 6.4rem;
                                &.point-gc {
                                    height: 2.7rem;
                                    border-radius: 2.4rem;
                                    font-weight: 700;
                                    display: inline-block;
                                    background-color: $color-grayb-400;
                                    color: #fff;
                                }
                            }
                            &.pro {
                                font-size: 1.4rem;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                padding: 0.3rem 1.4rem;
                                width: 6.4rem;
                                color: #2ebf93;
                                &.point-rc {
                                    height: 2.7rem;
                                    border-radius: 2.4rem;
                                    display: inline-block;
                                    background-color: $color-main-500;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
            .membership-box {
                .list {
                    // margin-bottom: 2.4rem;
                    li {
                        + li {
                            margin-top: 5.6rem;
                        }
                        .text {
                            text-align: center;
                            margin-bottom: 1.6rem;
                            p {
                                font-size: 1.6rem;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                            }
                            span {
                                font-size: 1.2rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                color: $color-grayb-400;
                                display: block;
                            }
                        }
                        .box-wrap {
                            display: flex;
                            gap: 1.5rem;
                            justify-content: center;
                            align-items: center;
                            > div {
                                width: calc(100% / 2);
                                height: 14.4rem;
                            }
                            .box {
                                position: relative;
                                border-radius: 2.4rem;
                                background-color: #fff;
                                box-shadow: 0 0.2rem 0.8rem 0
                                    rgba(99, 99, 99, 0.2);

                                .sale {
                                    padding: 1.5rem 1.5rem 0 1.5rem;
                                    display: flex;
                                    justify-content: space-between;
                                    .point-r {
                                        font-size: 1.2rem;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: normal;
                                        color: #ff567b;
                                    }
                                    .through {
                                        display: block;
                                        font-size: 1rem;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: normal;
                                        color: $color-grayb-400;
                                        text-align: right;
                                        margin-left: auto;
                                    }
                                }
                                .plan {
                                    padding: 0 1.5rem;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-bottom: 2.1rem;
                                    p {
                                        font-size: 1.6rem;
                                        font-style: normal;
                                        font-weight: 700;
                                        line-height: normal;
                                    }
                                }
                                .conversion {
                                    font-size: 1rem;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    color: $color-grayb-600;
                                    margin-bottom: 0.8rem;
                                    text-align: center;
                                }
                            }
                            .point-box {
                                position: relative;
                                border-radius: 2.4rem;
                                background: linear-gradient(
                                    180deg,
                                    #2ebf93 0%,
                                    #8cf5b6 100%
                                );
                                box-shadow: 0 0.2rem 0.8rem 0
                                    rgba(99, 99, 99, 0.2);
                                .sale {
                                    padding: 1.5rem 1.5rem 0 1.5rem;
                                    display: flex;
                                    justify-content: space-between;
                                    .point-r {
                                        font-size: 1.2rem;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: normal;
                                        color: #ff567b;
                                    }
                                    .through {
                                        display: block;
                                        font-size: 1rem;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: normal;
                                        color: #fff;
                                        text-align: right;
                                        margin-left: auto;
                                    }
                                }
                                .plan {
                                    padding: 0 1.5rem;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-bottom: 2.1rem;
                                    p {
                                        font-size: 1.6rem;
                                        font-style: normal;
                                        font-weight: 700;
                                        line-height: normal;
                                        color: #fff;
                                    }
                                }
                                .conversion {
                                    font-size: 1rem;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    color: #0c6b6a;
                                    margin-bottom: 0.8rem;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
                .reference {
                    text-align: center;
                    height: 7.5rem;
                    display: grid;
                    align-items: center;
                    .reference-s2 {
                        font-size: 1.2rem;
                        color: $color-grayb-400;
                    }
                }
            }
        }
        .pd-20 {
            + .pd-20 {
                border-top: 2rem solid $color-grayb-50;
            }
        }
    }
}
.membership-box {
    button {
        @include body-bold($color-grayb-300);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1rem;
        border-radius: 1.2rem;
        width: calc(100% - 2rem);
        height: 3.6rem;
        background: $color-grayb-50;
    }
}
.box {
    button {
        color: $color-main-600;
        background-color: $color-main-50;
    }
}
.point-box {
    button {
        color: #148176;
        background-color: $color-main-50;
    }
}
.faq-wrap {
    .faq-title {
        margin-top: 2.8rem;
        .title-m {
            margin-bottom: 0.8rem;
            font-size: 2.4rem;
            text-align: center;
        }
        .reference {
            margin-bottom: 2.4rem;
            text-align: center;
            .reference-s2 {
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: #000;
            }
        }
        .search-wrap {
            width: 100%;
            height: 4rem;
            padding: 0.6rem 3.7rem 0.6rem 4rem;
            margin: 0 auto 2.4rem;
            border: 0.1rem solid #dbe2df;
            background-color: $color-grayb-50;
            position: relative;
            border-radius: 0.8rem;
            button {
                width: 2.4rem;
                height: 2.4rem;
                position: absolute;
                &.search-button {
                    left: 0.8rem;
                }
                &.close-button {
                    right: 0.8rem;
                }
            }
            input {
                @include body-me($black00);
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                line-height: normal;
                background-color: transparent;
                border: 0;
                &:focus {
                    caret-color: unset;
                }
                &::placeholder {
                    color: $color-grayb-500;
                }
            }
        }
    }
    .help-box {
        padding: 0 2rem 4rem;
        text-align: center;
        .title-s {
            margin-bottom: 1.6rem;
        }
        .inquiry {
            display: inline-block;
            // width: 7.9rem;
            padding: 0.8rem 1.6rem;
            color: #2ebf93;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            background-color: #f1fcf6;
            border-radius: 0.8rem;
        }
    }
    .terms-warp {
        padding: 3.2rem 1.6rem;
        background-color: $color-grayb-50;
        li {
            + li {
                margin-top: 1.6rem;
            }
            a {
                font-size: 1.2rem;
                font-weight: 500;
                color: $color-grayb-500;
            }
        }
    }
}

// 일지쓰기
.diary-writing {
    width: 100%;
    height: 100%;
    .member-plan {
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 72rem;
        margin-bottom: 5.1rem;
        padding: 0 2rem;
        > div {
            padding: 1.4rem 2rem;
            height: 4.8rem;
            border-radius: 0.4rem;
            background-color: #212121;
            display: flex;
            gap: 0.8rem;
            align-items: center;
            img {
                align-self: baseline;
            }
            p {
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #fff;
            }
            a {
                margin-left: auto;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: $color-main-500;
            }
        }
    }
}
.img-wrap {
    width: 100%;
    .add-img {
        button {
            width: 21rem;
            height: 12rem;

            border-radius: 0.6rem;
            border: 0.05rem dashed #a4b7af;
            background-color: $color-grayb-50;
            &.write-photo {
                // padding: 0.8rem 1.6rem 0 1.6rem;
                border-radius: 1.6rem;
                border: 0.05rem dashed $color-grayb-500;
                background-color: $color-grayb-50;
            }
            &.write-photo2 {
                // padding: 0.8rem 1.6rem 0 1.6rem;
                border-radius: 1.6rem;
                border: 0.05rem solid $color-grayb-500;
                background-color: $color-grayb-50;
            }
            img {
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }
    .added-img {
        position: relative;
        width: 21rem;
        height: 12rem;
        border-radius: 1.6rem;
        overflow: hidden;
        .btn-added-img {
            position: absolute;
            top: 0.4rem;
            right: 0.4rem;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.animal-name {
    font-size: 14px;
    color: $color-main-500;
}
.writer-info-wrap {
    // display: flex;
    // justify-content: center;
    // 닉네임 옆으로 붙어서 주석처리함. 왜이랬지?
    margin-top: 0.3rem;
    font-weight: 600;
    .community-detail & {
        flex-direction: column;
    }
}
